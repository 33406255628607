export const DEFAULT_LINK: string ="https://gw.bisiparis.de"
//export const DEFAULT_LINK: string ="http://192.168.1.155:8000"




export const Config_AccountMenu="/Mobile/Setting/GetMenu"
export const Config_Login="/Aut/Account/Login"


//Brands
export const Config_GetBrands="/Admin/Brand/GetBrands"
export const Config_UpdateBrand="/Admin/Brand/UpdateBrand"
export const Config_AddBrand="/Admin/Brand/AddBrand"
export const Config_DeleteBrand="/Admin/Brand/DeleteBrand"

//Insert
export const Config_GetInsert="/Web/Setting/GetInsert"
export const Config_GetInsertProducts="/Web/Setting/GetInsertProducts"

//Dashboard
export const Config_GetDashboardDailyPlatform="/Admin/Dashboard/GetDailyRate"
export const Config_GetDashboardWeeklyRate="/Admin/Dashboard/GetWeeklyRate"


//Product
export const Config_GetBasketProducts="/Mobile/Product/GetBasketProducts"
export const Config_GetProduct="/Admin/Product/GetProducts"
export const Config_UpdateProduct="/Admin/Product/UpdateProducts"
export const Config_AddProduct="/Admin/Product/AddProducts"
export const Config_GetProductSearch="/Web/Product/GetSearch"
export const Config_UpdateProducts="/Admin/Product/UpdateProducts"
export const Config_GetProductsFilter="/Admin/Product/GetProductsFilter"
export const Config_DeleteProducts="/Admin/Product/DeleteProducts"
export const Config_GetProductsIds="/Web/Product/GetProductsIds"



//Basket
export const Config_GetBasket="/Web/Basket/GetBasket"
export const Config_GetDecrementBasket="/Mobile/Basket/DecrementBasket"
export const Config_AddBasket="/Mobile/Basket/AddBasket"
export const Config_UpdateBasket="/Mobile/Basket/UpdateBasket"
export const Config_DeleteBasket="/Mobile/Basket/DeleteBasket"


//Order
export const Config_GetOrder="/Admin/Order/GetOrders"
export const Config_GetOrderProducts="/Web/Order/GetOrderProducts"
export const Config_AddOrder="/Web/Order/AddOrder"

export const Config_GetOrdersOpened="/Admin/Order/GetOrdersOpened"
export const Config_GetOrdersComplated="/Admin/Order/GetOrdersComplated"
export const Config_GetOrdersCanceled="/Admin/Order/GetOrdersCanceled"


export const Config_GetOrderUpdate="/Admin/Order/UpdateOrder"
export const Config_GetOrdersTotalAmount="/Admin/Order/GetOrdersTotalAmount"
export const Config_GetOrdersTotalCount="/Admin/Order/GetOrdersTotalCount"
export const Config_ServisGetLastOrdersProducts="/Admin/Order/GetLastOrdersProducts"
export const Config_ServisGetLastOrdersUsers="/Admin/Order/GetLastOrdersUsers"


//Price
export const Config_GetPrices="/Admin/Prices/GetPrices"
export const Config_GetGetPriceTypes="/Admin/Prices/GetPriceTypes"
export const Config_GetUpdatePrice="/Admin/Prices/UpdatePrice"
export const Config_AddPrice="/Admin/Prices/AddPrice"
export const Config_DeletePrice="/Admin/Prices/DeletePrice"


//Unit
export const Config_GetUnits="/Admin/Units/GetUnits"
export const Config_GetUnitsProduct="/Admin/Units/GetUnitsProduct"
export const Config_AddUnitProduct="/Admin/Units/AddUnitProduct"
export const Config_ChangeUnitProduct="/Admin/Units/ChangeUnitProduct"
export const Config_UpdateUnitProduct="/Admin/Units/UpdateUnitProduct"


//Member
export const Config_Member_Register_Link ="/Aut/Account/Register"
export const Config_GetMember="/Web/Member/GetMember"
export const Config_AddMember="/Admin/Member/AddMember"

export const Config_GetAdminUser="/Admin/Member/GetAdminUser"

export const Config_MemberUpdate="/Admin/Member/MemberUpdate"
export const Config_GetMemberAdress="/Web/Member/GetMemberAdress"
export const Config_AddMemberAdress="/Admin/Member/AddMemberAdress"
export const Config_UpdateMemberAdress="/Admin/Member/UpdateMemberAdress"
export const Config_DeleteMemberAdress="/Admin/Member/DeleteMemberAdress"
export const Config_GetMemberSettings="/Admin/Member/GetMemberSettings"
export const Config_UpdateShopingAdress="/Admin/Member/UpdateShopingAdress"
export const Config_UpdateInvoiceAdress="/Admin/Member/UpdateInvoiceAdress"

//Category
export const Config_GetCategory="/Admin/Category/GetCategories"
export const Config_AddCategory="/Admin/Category/AddCategory"
export const Config_UpdateCategory="/Admin/Category/UpdateCategory"
export const Config_DeleteCategory="/Admin/Category/DeleteCategory"
export const Config_GetSubCategories="/Web/Category/GetSubCategories"


export const Config_GetProductCategories="/Web/Category/GetProductCategories"
export const Config_AddProductCategories="/Admin/Category/AddProductCategory"
export const Config_UpdateProductCategories="/Admin/Category/UpdateProductCategory"
export const Config_DeleteProductCategories="/Admin/Category/DeleteProductCategory"



//MainGroup
export const Config_MainGroup="/Admin/MainGroup/GetMainGroups"

//OrderSituation
export const Config_GetOrderSituations="/Admin/OrderSituation/GetOrderSituations"

//Svg
export const Config_GetSvgId="/Admin/Svg/GetSvgId"
export const Config_GetSvg="/Admin/Svg/GetSvg"
export const Config_AddSvg="/Admin/Svg/AddSvg"
export const Config_UpdateSvg="/Admin/Svg/UpdateSvg"
export const Config_DeleteSvg="/Admin/Svg/DeleteSvg"

//Campain
export const Config_GetCampains="/Web/Campain/GetCampains"
export const Config_AddCampain="/Admin/Campain/AddCampain"
export const Config_UpdateCampain="/Admin/Campain/UpdateCampain"
export const Config_DeleteCampain="/Admin/Campain/DeleteCampain"

//Delivery
export const Config_GetWeekDays="/Admin/DeliverySetting/GetDays"
export const Config_DeleteDays="/Admin/DeliverySetting/DeleteDays"

export const Config_GetDeliveryTimes="/Admin/DeliverySetting/GetDeliveryTime"
export const Config_GetDeliveryTypes="/Admin/DeliverySetting/GetDeliveryTypes"

//Payment
export const Config_GetPaymentTypes="/Admin/PaymentSetting/GetPaymentTypes"
export const Config_UpdatePaymentTypes="/Admin/PaymentSetting/UpdatePaymentTypes"
export const Config_DeletePaymentTypes="/Admin/PaymentSetting/DeletePaymentTypes"


//Favorite
export const Config_GetFavorite="/Web/Favorite/GetFavorites"
export const Config_AddFavorite="/Web/Favorite/AddFavorite"
export const Config_DeleteFavorite="/Web/Favorite/DeleteFavorite"


//SearchHits
export const Config_GetSearchHits="/Admin/Setting/GetSearchHits"

//GlobalSettings
export const Config_GetGlobalSettings="/Web/Setting/GetGlobalSettings"




//Geo
export const Config_GetCountry="/Mobile/Setting/GetGeoCountry"
export const Config_GetCity="/Mobile/Setting/GetGeoCity"
export const Config_GetTown="/Mobile/Setting/GetGeoTown"


//Eski Servis
export const Config_Token_Link: string ="/token"

//export const Config_Member_Register_Link: string ="/api/v2/Member/Register"

export const Config_Member_Login: string ="/api/v2/Member/Login"
