// src/Login.js
import React, { useEffect, useRef, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import { AccountList } from '../../components/AccountList';
import { MembershipInformation } from '../../components/MembershipInformation';
import MemberStore from '../../Stores/MemberStore';

const AccountMySettingsPages = () => {
    const navigate = useNavigate();
    const [degistir, setDegister] = useState<string>("")
 
    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("NavigationBar useEffect")


    }, [LanguageStore.language, MainStore.PageRefresh]);

    function handleSave(): void {
        //setDegistirValue(!degistirValue)
        
        MainStore.setPageRefresh(!MainStore.getPageRefresh())
    }

    return (
        <><SearchBar /><NavbarButtons />
            <div className="container">
                <hr />
                <div className="col-12 d-flex justify-content-between flex-wrap">
                    <AccountList style4={{ color: "#E84B56" }} />
                    <div className="col-12 col-md-8 ">
                        <div className="row  d-flex justify-content-between ">
                            <div className="col-12 mt-3" style={{ fontWeight: "bold" }}> <h3> {i18n.t("hesabim3")} </h3></div>
                            <div className="col-12 col-md-5 ">
                                <MembershipInformation degistir={"tel"} setDegister={setDegister} yazi1={'Telefon'} yazi2={MemberStore.getMember()?.phone as ""} yazi3='Onaylandı' style1={{ color: "green", fontWeight: "bold" }}/>
                                <MembershipInformation degistir={"ad"} setDegister={setDegister} yazi1={i18n.t("Ad")} yazi2={MemberStore.getMember().name} />
                            </div>
                            <div className="col-12 col-md-5 ">
                                <MembershipInformation degistir={"eposta"} setDegister={setDegister} yazi1={i18n.t("EPosta")} yazi2={MemberStore.getMember().email} yazi3={i18n.t("onaysiz")} style1={{ color: "red", fontWeight: "bold" }} />
                                <MembershipInformation degistir={"soyad"} setDegister={setDegister} yazi1={i18n.t("Soyad")} yazi2={MemberStore.getMember().surname} />
                            </div>
                            <div className="row">
                                <div className="col-12 md-col-4">
                                    <div className="col-12 d-flex justify-content-start">
                                        <button onClick={e => handleSave()} type="submit" className="col-4 btn btn-outline-dark mt-3 mb-4" >
                                            {i18n.t("degisikliklerikaydet")}
                                        </button>
                                    </div>
                                    <div className="col-12 d-flex justify-content-start">
                                        <button type="submit" className="col-4 btn btn-outline-danger mb-4" >
                                            {i18n.t("hesabimisil")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mb-3"> <h3>{i18n.t("iletisimtercihi")}</h3></div>
                            <div className="row">
                                <div className="col-12 ">
                                    <input className="form-check-input" type="radio" name="radioNoLabel" id="radioNoLabel1" value="" aria-label="..." />
                                    <span style={{ marginLeft: "5px" }}>{i18n.t("bilgilendirmeamacli")} <span style={{ textDecoration: "underline" }}>{i18n.t("smsonay")}</span></span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <input className="form-check-input" type="radio" name="radioNoLabe2" id="radioNoLabel1" value="" aria-label="..." />
                                    <span style={{ marginLeft: "5px" }}>{i18n.t("bilgilendirmeamacli")} <span style={{ textDecoration: "underline" }}>{i18n.t("cagrionay")}</span></span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-2">
                                    <input className="form-check-input" type="radio" name="radioNoLabe3" id="radioNoLabel1" value="" aria-label="..." />
                                    <span style={{ marginLeft: "5px" }}>{i18n.t("bilgilendirmeamacli")} <span style={{ textDecoration: "underline" }}>{i18n.t("epostaonay")}</span></span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 md-col-4 d-flex justify-content-start">
                                    <button type="submit" className="col-4 btn btn-outline-dark mt-3 mb-4" >
                                        {i18n.t("degisikliklerikaydet")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        </>
    );
};
const ObserverAccountMySettingsPages = observer(AccountMySettingsPages);
export default ObserverAccountMySettingsPages;
function setShowModal(arg0: boolean) {
    throw new Error('Function not implemented.');
}

