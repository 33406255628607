import { useRef } from "react"
import BasketStore from "../Stores/BasketStore"
import i18n from "../config/languageConf"
import { order } from "../config/svgs"
import { getCurrencyIcon } from "./getCurrencyIcon"
import { useNavigate } from "react-router"

interface orderinter {

    style1?: {}
    style2?: {}
    style3?: {}
    style4?: {}
}
export function AccountList(order: orderinter) {
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const myInputRef = useRef(null);
    const myModalRef = useRef(null);
    const navigate = useNavigate();

    const handleAdreslerimClick = () => {
        navigate('/account/myadress');
    };

    const handleSiparislerimClick = () => {
        navigate('/account/myorders');
    };

    const handleFavorilerimClick = () => {
        navigate('/favorites');
    };

    const handleHesapAyarlarıClick = () => {
        navigate('/account/mysetting');
    };
    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.currentTarget.style.cursor = 'pointer';
      };
    
    return (
        <>
            <div className="col-12 col-md-3 border rounded mt-3" style={{maxHeight:"230px"}}>
                <div className="col-12  d-flex justify-content-around mt-3" style={order.style1} onClick={handleAdreslerimClick} onMouseEnter={handleMouseEnter}>
                    <div className="col-1 ms-2"><i className="fa fa-map-marker" aria-hidden="true"></i></div>
                    <div className="col-8 ">{i18n.t("Adreslerim")}</div>
                    <div className="col-1 "><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                </div>
                <hr />
                <div className="col-12  d-flex justify-content-around mt-2" style={order.style2} onClick={handleSiparislerimClick} onMouseEnter={handleMouseEnter}>
                    <div className="col-1 ms-2"><i className="fa-solid fa-building-user"></i></div>
                    <div className="col-8 ">{i18n.t("Siparişlerim")}</div>
                    <div className="col-1 "><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                </div>
                <hr />
                <div className="col-12  d-flex justify-content-around mt-2" style={order.style3} onClick={handleFavorilerimClick} onMouseEnter={handleMouseEnter}>
                    <div className="col-1 ms-2"><i className="fa-solid fa-heart"></i></div>
                    <div className="col-8 ">{i18n.t("Favorilerim")}</div>
                    <div className="col-1 "><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                </div>
                <hr />
                <div className="col-12  d-flex justify-content-around mt-2 mb-3" style={order.style4} onClick={handleHesapAyarlarıClick} onMouseEnter={handleMouseEnter}>
                    <div className="col-1 ms-2"><i className="fa-solid fa-gear"></i></div>
                    <div className="col-8 ">{i18n.t("hesapayarlari")}</div>
                    <div className="col-1 "><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                </div>
            </div>

        </>
    )

}

function setShowModal(arg0: boolean) {
    throw new Error("Function not implemented.")
}
