import { observer } from "mobx-react";
import i18n from "../../config/languageConf";
import SearchBar from "../../components/SearchBar";
import NavbarButtons from "../../components/navbarButtons";
import { useEffect, useState } from "react";
import { ServisGetInsert, ServisGetInsertProduct } from "../../services/InsertServis";
import InsertStore from "../../Stores/InsertStore";
import { ServisGetProductsIds } from "../../services/ProductServis";
import ProductCard from "../../components/ProductCard";
import PagesHeaderLinks from "../../components/PagesHeaderLinks";
import { CampainFilter } from "../../components/CampainFilter";

function CampainsPage() {
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        try {
            const fetchData = async () => {
                try {
                    const inserts = await ServisGetInsert();
                    InsertStore.setınsert(inserts);

                    for (const insert of inserts) {
                        const iproduct = await ServisGetInsertProduct(insert.id);
                        console.log("ServisGetInsertProduct > " + iproduct.length);
                        InsertStore.setInsertProduct([...InsertStore.getInsertProduct(), ...iproduct]);

                        const productIds = iproduct
                            .filter((insert) => insert.productId !== null && insert.productId !== undefined)
                            .map((insert) => insert.productId);
                        console.log("ids conuts > " + productIds.length);
                        console.log("ids > " + productIds);

                        const products = await ServisGetProductsIds(productIds);
                        console.log("ServisGetProductsIds > " + products.length);
                        InsertStore.setInsertProductProducs([...InsertStore.getInsertProductProducs(), ...products]);

                        console.log("home insert product > " + InsertStore.getInsertProductProducs().length);
                    }

                    setIsloading(false);
                } catch (error) {
                    console.error("Error in useEffect:", error);
                }
            };

            fetchData();
        } catch {
            console.log("mainhome screeen failed")
        }
    }, [])

    return (
        <>
            <SearchBar />
            <NavbarButtons />
            <div className="container">
               <PagesHeaderLinks text={i18n.t("Kampanyalar")} />
                <div className="row">
                    <CampainFilter />
                    <div className="col-12 col-md-9">
                        <div className="row mt-5 mt-md-5">
                            <div className="col-12 col-md-8">
                                {i18n.t("buguneozelkampanyalar")}
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                {i18n.t("CokSatanlar")}
                            </div>
                            <div className="row mt-5 ">
                                {
                                    isLoading ?
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border  text-danger" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        InsertStore.getInsertProductProducs().map((item) => {
                                            return (
                                                <>
                                                    <div className="col-6 col-md-3">
                                                        <ProductCard product={item} />
                                                    </div>
                                                </>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

const ObserverSCampainsPage = observer(CampainsPage);
export default ObserverSCampainsPage;