import i18n from "../config/languageConf";
import { redline } from "../config/svgs";
import { convertSVGToDataURI } from "./svgtoImage";

type headerType = {
    headerLeftText: string;
    headerRightText: string;
    headerLeftDisplayed?: boolean;
    headrightDisplayed?: boolean;
}

export function HeaderText(header: headerType) {
    return (
        <div className="container">
            <div className="row">
                {
                    header.headerLeftDisplayed != false ?
                        <div className="col-12 col-md-6 d-flex justify-content-start text-baslik">
                            <img style={{ marginRight: "10px" }} src={convertSVGToDataURI(redline)} alt="" />
                            {header.headerLeftText}
                        </div> : <div className="col-12 col-md-6"></div>
                }

                {
                    header.headrightDisplayed != false ?
                        <div className="col-6 d-flex justify-content-end align-items-center text-red">
                            {header.headerRightText}
                            <i className='fas fa-chevron-right ms-2'></i>
                        </div> : <div className="col-12 col-md-6"></div>
                }


            </div>
        </div>
    )
}