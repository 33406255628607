// src/Login.js
import React, { useEffect, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import { AccountList } from '../../components/AccountList';
import { Dropdown } from 'react-bootstrap';
import OrderStore from '../../Stores/OrderStore';
import { ServisGetOrder } from '../../services/OrderServis';
import OrderCard from '../../components/Account/OrderCard';

const AccountMyOrdersPages = () => {
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("NavigationBar useEffect")
        async function start() {

            const orders = await ServisGetOrder()
            OrderStore.setOrderOrigin(orders)
            console.log("ali >" + JSON.stringify(OrderStore.getOrderOrigin()))
            setIsloading(false)
        }

        start()


    }, [LanguageStore.language, MainStore.PageRefresh]);

    return (
        <><SearchBar /><NavbarButtons />
            <div className="container">
                <hr />
                <div className="col-12 d-flex justify-content-between flex-wrap">
                    <AccountList style2={{ color: "#E84B56" }} />
                    {isLoading ?
                    
                    <div className="col-9">
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border  text-danger" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    </div>
                 :
                    OrderStore.getOrderOrigin()  ? 
                        <>
                            <div className="col-12 col-md-9 border rounded mt-3">
                                <div className="col-12 ms-3 mt-3">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ fontWeight: "bold" }}>
                                            {i18n.t("son1ay")}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                {
                                    OrderStore.getOrderOrigin()?.map(o => {
                                        return (
                                            <>
                                                <OrderCard order={o} />
                                            </>
                                        )
                                    })

                                }


                            </div>
                        </>


                        :

                        <div className="col-12 col-md-9 border rounded mt-3 d-flex align-items-center">
                            <div className="row ">
                                <div className="col-12 col-md-6 mt-3 ms-4 mb-5">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ fontWeight: "bold" }}>
                                            {i18n.t("son1ay")}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="row d-flex justify-content-center ms-3 mt-4">
                                    <div className="col-12 d-flex justify-content-center" style={{ color: "gray" }}>{i18n.t("son1aysiparisbulunmuyor")}</div>
                                    <div className="col-12 col-md-5 mb-5 d-flex justify-content-center mt-3">
                                        <button type="submit" className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                            {i18n.t("alisverisebasla")}
                                        </button>
                                    </div>
                                </div>

                            </div>


                        </div>
                    }
                </div>

            </div>
        </>
    );
};
const ObserverAccountMyOrdersPages = observer(AccountMyOrdersPages);
export default ObserverAccountMyOrdersPages;
