import {  Config_AddFavorite, Config_DeleteFavorite, Config_GetFavorite, DEFAULT_LINK } from "./servisconfig";
import { IFavorite } from "../Models/IFavorite";

export async function ServisGetFavorites(): Promise<IFavorite[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_GetFavorite, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(token)
    }).then((response) => response.json()).then((json) => {
        console.log("servis gelen favorite" + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBasket Service Error: " + error);
    });
    return data;
}

export async function ServisAddFavorites(favorite:IFavorite): Promise<IFavorite[]> {
    const token = localStorage.getItem('token');
    console.log('ServisAddFavorites' + favorite)
    
    var data = fetch(DEFAULT_LINK + Config_AddFavorite, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(favorite),
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBasket Service Error: " + error);
    });
    return data;
}

export async function ServisDeleteFavorites(favorite:IFavorite): Promise<IFavorite[]> {
    const token = localStorage.getItem('token');
    console.log('ServisAddFavorites' + favorite)
    
    var data = fetch(DEFAULT_LINK + Config_DeleteFavorite, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(favorite),
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBasket Service Error: " + error);
    });
    return data;
}