import { observer } from "mobx-react";
import { IOrder } from "../../Models/IOrder";
import { useEffect, useState } from "react";
import { getCurrencyIcon } from "../getCurrencyIcon";
import { ServisGetOrderProducts } from "../../services/OrderServis";
import getImageUrl from "../getImage";
import OrderStore from "../../Stores/OrderStore";
import { IProduct } from "../../Models/IProduct";

type OrderCardProps = {
    props?: any;
    order:IOrder;
}

function OrderCard(item: OrderCardProps) {
    //@ts-ignore
    const [seletecOrder, setSeletecOrder] = useState<IProduct>({})

    useEffect(() => {
        async function start() {
            console.log("OrderCard useEffect")
            const orderProduct = await ServisGetOrderProducts(item.order.id)
            console.log("orderProduct >" + JSON.stringify(orderProduct))
            setSeletecOrder(orderProduct[0]);
            
        }
        start()

    }, [item]);
  return (
    <><div className="col-12 d-flex justify-content-center mt-4">
          <div className="col-10 d-flex justify-content-center">
              <div className="col-3"> 
                <img width={50} height={50} src={getImageUrl(seletecOrder)} alt="" />
              </div>
              <div className="col-9">
                  <div className="col-12" style={{ fontWeight: "bold" }}>
                      {seletecOrder?.product_name}
                  </div>
                  <div className="col-12 d-flex justify-content-start" style={{ color: "gray" }}>
                      {item.order.orderTime.toString()}
                  </div>
              </div>
          </div>
          <div className="col-1 d-flex justify-content-end" style={{ color: "#E32532", fontWeight: "bold" }}>{item.order.orderTotal.toString()} {getCurrencyIcon()}</div>
      </div><hr /></>
  );
}
const ObserverOOrderCard = observer(OrderCard);
export default ObserverOOrderCard;