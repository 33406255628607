// src/Login.js
import React, { useEffect, useRef, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import { AccountList } from '../../components/AccountList';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import MemberAdresStore from '../../Stores/MemberAdresStore';
import MemberStore from '../../Stores/MemberStore';
import { GetCityName, GetTownName } from '../../common/GetCityAndTown';
import GeoStore from '../../Stores/GeoStore';
import { IMemberAdres } from '../../Models/IMemberAdres';
import { tr } from '../../lang/tr';

const AccountMyAdressPages = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);


    const handleClose = () => {
        setShowModal(false)
        setShowDelete(false)
    };
    const handleShow = () => {
        //@ts-ignore
        MemberAdresStore.setSelectedAdress(null)
        setShowModal(true)

    };
    const handleDelete = (selectedAdress:IMemberAdres) => {
        //@ts-ignore
        MemberAdresStore.setSelectedAdress(selectedAdress)
        setShowDelete(true)

    };
    const myInputRef = useRef(null);
    const myModalRef = useRef(null);
    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("AccountMyAdressPages useEffect")
        console.log(MemberStore.getMember())
        console.log("Adress > " + MemberAdresStore.getMemberAdress())
        const selectedAdress = MemberAdresStore.getMemberAdress().find(adress => adress.isSelectedShipping == true);
        if (selectedAdress) {
            console.log("Address > " + JSON.stringify(selectedAdress));
            MemberAdresStore.setSelectedAdress(selectedAdress);
        }
    }, [LanguageStore.language, MainStore.PageRefresh]);
    function handleAdresEdit(selectedAdress: IMemberAdres): void {
        MemberAdresStore.setSelectedAdress(selectedAdress)
        setShowModal(true)
    }
    return (
        <>
            <Modal className="modal modal-lg" show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Teslimat Adresi Ekle</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <h5>{i18n.t("adresbilgisi")}</h5>
                    </div>

                    <div className="col-12 d-flex justify-content-between flex-wrap  ">

                        <div className="col-6 col-md-5 mt-2">
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-stores" >
                                    {i18n.t("sehir")}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="col-6 col-md-5 mt-2">
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-stores" >
                                    {i18n.t("ilce")}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className="col-12 mt-2 d-flex justify-content-between ">
                        <div className="col-6 col-md-5">
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-stores" >
                                    {i18n.t("mahalle")}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div className="col-6 col-md-5 border rounded d-flex justify-content-center align-items-center">
                            <div className="input-group">
                                <input style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("caddesokak")} />
                            </div>
                        </div>

                    </div>
                    <div className="col-12 d-flex justify-content-between mt-2">
                        <div className="col-3 border rounded">
                            <div className="input-group">
                                <input value={MemberAdresStore.getSelectedAdress()?.geoDistId} style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("binano")} />
                            </div>
                        </div>
                        <div className="col-3 border rounded"><div className="input-group">
                            <input value={MemberAdresStore.getSelectedAdress()?.geoDistId} style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("katno")} />
                        </div>
                        </div>
                        <div className="col-3 border rounded"><div className="input-group">
                            <input value={MemberAdresStore.getSelectedAdress()?.geoDistId} style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("daire")} />
                        </div>
                        </div>
                    </div>
                    <div className="col-12 border rounded mt-2">
                        <div className="input-group">
                            <input value={MemberAdresStore.getSelectedAdress()?.adress} style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("adrestarifi")} />
                        </div>
                    </div>
                    <div className="col-12 border rounded mt-2">
                        <div className="input-group">
                            <input value={MemberAdresStore.getSelectedAdress()?.adresTitle} style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("adresbasligi")} />
                        </div>
                    </div>
                    <div className="col-12 mt-3 mb-3"><h5>{i18n.t("kisiselbilgiler")}</h5></div>
                    <div className="col-12  mt-2  d-flex justify-content-between">
                        <div className='col-5 border rounded'>
                            <div className="input-group">
                                <input value={MemberAdresStore.getSelectedAdress()?.nameSurname.toUpperCase()} style={{ border: "none" }} type="text" className="form-control" placeholder="ALİ CEM" />
                            </div>
                        </div>
                        <div className='col-5 border rounded'>
                            <div className="input-group">
                                <input value={MemberAdresStore.getSelectedAdress()?.phone} style={{ border: "none" }} type="text" className="form-control" placeholder="KARTAL" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-12 md-col-4 d-flex justify-content-start">
                        <button type="submit" className="col-12 btn btn-danger" >
                            {i18n.t("Kaydet")}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal className="modal modal-lg" show={showDelete} onHide={handleClose}>
                
                    
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{i18n.t("adressilme")}</h5>
                                <button onClick={e => handleClose()} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <p>{MemberAdresStore.getSelectedAdress()?.adresTitle}</p>
                                <p>{MemberAdresStore.getSelectedAdress()?.adress}</p>
                                <p style={{fontWeight:"bold"}}>{i18n.t("adressilmeonay")}</p>
                                

                            </div>
                            <div className="modal-footer">
                                <button onClick={e => handleClose()} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{i18n.t("hayir")}</button>
                                <button type="button" className="btn btn-danger">{i18n.t("evet")}</button>
                            </div>
                        </div>
                    
                
            </Modal>
            <SearchBar /><NavbarButtons />
            <div className="container">

                <hr />
                <div className="col-12 d-flex justify-content-between flex-wrap">
                    <AccountList style1={{ color: "#E84B56" }} />
                    <div className="col-12 col-md-8 ">
                        <div className="row">
                            <div className="col-12 mt-3" style={{ fontWeight: "bold" }}> <h3> {i18n.t("Adreslerim")} </h3></div>
                            <div className="col-12 col-md-6 ">
                                <div className="col-12 mt-4" style={{ fontWeight: "bold" }}>{i18n.t("teslimatadreslerim")}</div>
                                <div onClick={handleShow} style={{ cursor: "pointer" }} className="col-12 newadressborder rounded d-flex justify-content-center align-items-center">
                                    <div className="col-1 mt-2 mb-2"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                    <div className="col-10 mt-2 mb-2">{i18n.t("teslimatadresiekle")}</div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 ">
                                <div className="col-12 mt-4" style={{ fontWeight: "bold" }}>{i18n.t("faturaadreslerim")}</div>
                                <div onClick={handleShow} style={{ cursor: "pointer" }} className="col-12 newadressborder rounded d-flex justify-content-center align-content-center">
                                    <div className="col-1 mt-2 mb-2"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                    <div className="col-10 mt-2 mb-2">{i18n.t("teslimatadresiekle")}</div>
                                </div>
                            </div>
                            {
                                MemberAdresStore.getMemberAdress()?.map(adress => {


                                    return (
                                        <div key={adress.id} className="col-12 col-md-6 ">

                                            <div className="col-12 border rounded mt-4">
                                                <div className="col-12 d-flex justify-content-end mt-3">
                                                    <i onClick={e => handleAdresEdit(adress)} style={{ cursor: "pointer" }} className="fa fa-pencil" aria-hidden="true"></i>
                                                    <i onClick={e => handleDelete(adress)} style={{ cursor: "pointer" }} className="fa fa-trash ms-2 me-2" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-12 ms-2" style={{ fontWeight: "bold" }}>{adress.adresTitle}</div>
                                                <div className="col-12 ms-2 mb-2" style={{ color: "gray" }}>{adress.adress} {GetTownName(adress.geoCityId, adress.geoTownId)} / {GetCityName(adress.geoCityId)}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};
const ObserverAccountMyAdressPages = observer(AccountMyAdressPages);
export default ObserverAccountMyAdressPages;
