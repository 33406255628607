import BasketStore from "../Stores/BasketStore"
import i18n from "../config/languageConf"
import { getCurrencyIcon } from "./getCurrencyIcon"

interface orderinter {
    yazi1?: string,
    yazi2?: string,
    yazi3?: string
    style1?: {}
    style2?: {}
    style3?: {}
}
export function OrderSummery(order: orderinter) {
    return (
        <>
            <div className="col-12 d-flex justify-content-between mb-3 mt-3 ">
                <div className="col-6 d-flex justify-content-start" style={order.style1}>{order.yazi1}</div>
                <div className="col-6 d-flex justify-content-end" style={order.style2} >{order.yazi2}</div>
            </div>

        </>
    )

}