import { IMember } from "../Models/IMemberLogin";
import { Config_AddMember, Config_AddMemberAdress, Config_DeleteMemberAdress, Config_GetAdminUser, Config_GetMember, Config_GetMemberAdress, Config_MemberUpdate, Config_UpdateInvoiceAdress, Config_UpdateMemberAdress, Config_UpdateShopingAdress, DEFAULT_LINK } from "./servisconfig";
import { IMemberAdres, IMemberAdresResponse } from "../Models/IMemberAdres";
import { IAdminUser } from "../Models/IAdminUser";

export async function ServisGetMember(): Promise<IMember> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_GetMember, {
        method: "GET",
        headers: { "Content-type": "application/json", "Authorization": "Bearer " + token },
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetMember Service Error: " + error);
    });
    return data;
}

export async function ServisGetMemberAdress(): Promise<IMemberAdres[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_GetMemberAdress, {
        method: "GET",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
        //body: "username=" + details.username + "&password=" + details.password + "&grant_type=password"
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetMemberAdress Service Error: " + error);
    });
    return data;
}

export async function ServisUpdateMemberAdress(memberAdress:IMemberAdres): Promise<IMemberAdres[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_UpdateMemberAdress, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
        body:JSON.stringify(memberAdress) 
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateMemberAdress Service Error: " + error);
    });
    return data;
}

export async function ServisAddMemberAdress(adres:IMemberAdresResponse): Promise<IMemberAdres[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_AddMemberAdress, {
        method: "POST",
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin":"*",
        },
        body: JSON.stringify(adres)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddMemberAdress Service Error: " + error);
    });
    return data;
}

export async function ServisDeleteMemberAdress(adres:IMemberAdresResponse): Promise<IMemberAdres> {
    var data = fetch(DEFAULT_LINK + Config_DeleteMemberAdress, {
        method: "POST",
        //headers: { "Content-type": "application/json", "Authorization": "Bearer " + token },
        body: JSON.stringify(adres)
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteMemberAdress Service Error: " + error);
    });
    return data;
}

// export async function ServisGetMemberSetting(): Promise<IMemberSetting[]> {
//     const token = await AsyncStorage.getItem('token');

//     var data = fetch(DEFAULT_LINK + Config_GetMemberSettings, {
//         method: "GET",
//         //headers: { "Content-type": "application/json", "Authorization": "Bearer " + token },
//         headers: {
//             'Content-Type': 'application/json',
//             "Authorization": "Bearer " + token
//         },
//         //body: JSON.stringify(adres)
//     }).then((response) => response.json()).then((json) => {
//         console.log(JSON.stringify(json));
//         return json;
//     }).catch((error) => {
//         console.log("ServisGetMemberSetting Service Error: " + error);
//     });
//     return data;
// }

export async function ServisGetAdminUser(): Promise<IAdminUser> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetAdminUser, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(newMember)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddMember Service Error: " + error);
    });
    return data;
}

export async function ServisAddMember(newMember : IMember): Promise<IMember[]> {
    const token = localStorage.getItem('token');


    var data = fetch(DEFAULT_LINK + Config_AddMember, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(newMember)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddMember Service Error: " + error);
    });
    return data;
}

export async function ServisMemberUpdate(updateMember : IMember): Promise<IMember> {
    const token = localStorage.getItem('token');


    var data = fetch(DEFAULT_LINK + Config_MemberUpdate, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(updateMember)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisMemberUpdate Service Error: " + error);
    });
    return data;
}

// export async function ServisUpdateInvoiceAdress(AdressId:number): Promise<IMemberAdres[]> {
//     const token = await AsyncStorage.getItem('token');

//     var data = fetch(DEFAULT_LINK + Config_UpdateInvoiceAdress + "?adressId=" + AdressId , {
//         method: "GET",
//         //headers: { "Content-type": "application/json", "Authorization": "Bearer " + token },
//         headers: {
//             'Content-Type': 'application/json',
//             "Authorization": "Bearer " + token
//         },
//         //body: JSON.stringify(adres)
//     }).then((response) => response.json()).then((json) => {
//         console.log(JSON.stringify(json));
//         return json;
//     }).catch((error) => {
//         console.log("ServisUpdateInvoiceAdress Service Error: " + error);
//     });
//     return data;
// }

// export async function ServisUpdateShopingAdress(AdressId:number): Promise<IMemberAdres[]> {
//     const token = await AsyncStorage.getItem('token');

//     var data = fetch(DEFAULT_LINK + Config_UpdateShopingAdress + "?adressId=" + AdressId , {
//         method: "GET",
//         //headers: { "Content-type": "application/json", "Authorization": "Bearer " + token },
//         headers: {
//             'Content-Type': 'application/json',
//             "Authorization": "Bearer " + token
//         },
//         //body: JSON.stringify(adres)
//     }).then((response) => response.json()).then((json) => {
//         console.log(JSON.stringify(json));
//         return json;
//     }).catch((error) => {
//         console.log("ServisUpdateShopingAdress Service Error: " + error);
//     });
//     return data;
// }