// src/Login.js
import React, { useEffect, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { LoginServis } from '../../services/Loginservis';
import { IAdminLogin } from '../../Models/ILogin';
import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import { ServisGetMember } from '../../services/MemberServis';
import MemberStore from '../../Stores/MemberStore';
import { Link } from 'react-router-dom';
import { ServisGetBasket } from '../../services/BasketServis';
import BasketStore from '../../Stores/BasketStore';
import { IProduct } from '../../Models/IProduct';
import { ServisGetFavorites } from '../../services/FavoriteServis';
import FavoriteStore from '../../Stores/FavoriteStore';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import { toastMessage } from '../../common/ToastMessage';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        console.log("NavigationBar useEffect")


    }, [LanguageStore.language, MainStore.PageRefresh]);


    const handleSubmit = (event: any) => {

        event.preventDefault();
        console.log('Kullanıcı Adı:', username);
        console.log('Parola:', password);

        console.log("Login Scrren Login Member Servis Started")
        const loginMember: IAdminLogin = {
            email: username,
            password: password,
            platform: 'Web'
        }
        LoginServis(username, password).then(responseJson => {
            console.log(responseJson.token)
            if (responseJson.token) {
                try {
                    console.log("yazıldı")
                    localStorage.setItem('token', responseJson.token);
                    ServisGetMember().then(member => {
                        console.log("ServisGetMember > " + JSON.stringify(member))
                        MemberStore.setMember(member)
                    })
                    ServisGetBasket().then(basket => {
                        BasketStore.setBasket(basket)
                    });
                    ServisGetFavorites().then(favorites => {
                        console.log("login after favorite > " +  JSON.stringify(favorites))
                        FavoriteStore.setFavorite(favorites)
                    })
                    MainStore.setPageRefresh(!MainStore.getPageRefresh());

                    window.location.href = '/';
                } catch (e) {
                    console.log("error")
                }

            } else {
                console.log("else > " + JSON.stringify(responseJson))
                console.log("asdasd")
                toastMessage(i18n.t("KullaniciBilgileriDoğrulanamadı"))

            }
        }).catch((error) => {
            console.log("error")

        })
    };

    return (
        <div className="row">
            <div className='col-12 md-col-6 mt-5 mb-5'>
                <img width={"100%"} height={"30%"} src={require('../../assets/registerpage.png')} />
                <div className="row d-flex justify-content-center loginbox">
                    <div className="col-8 col-md-4 register-border  ">
                        <div className='col-12 mt-3' style={{ fontWeight: "bold", color: "red", marginBottom: "10px" }}><h2>{i18n.t("girisyap")}</h2></div>
                        <div className="col-12 mt-2" style={{ color: "gray" }}>{i18n.t("logintext")}</div>
                        <div>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group ">
                                    <label htmlFor="username"></label>
                                    <input
                                        placeholder={i18n.t("telefonmail")}
                                        type="text"
                                        className="form-control"
                                        onChange={e => { setUsername(e.target.value); } }
                                        required />
                                </div>
                                <div className="form-group mb-4">
                                    <label htmlFor="password"></label>
                                    <input
                                        placeholder={i18n.t("sifre")}
                                        type="password"
                                        className="form-control"
                                        onChange={(e) => setPassword(e.target.value)}
                                        required />
                                </div>
                                <div className="col-12 d-flex justify-content-center">
                                    <button type="submit" className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                        {i18n.t("girisyap")}
                                    </button>
                                </div>
                            </form>
                            <div className="col-12 mb-4">{i18n.t("hesabinyoksa")}<Link style={{ color: "black", marginLeft: "10px" }} to={'/register'}>{i18n.t("kayitol")}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const ObserverLogin = observer(Login);
export default ObserverLogin;
