import { observer } from "mobx-react";
import i18n from "../../config/languageConf";
import SearchBar from "../../components/SearchBar";
import NavbarButtons from "../../components/navbarButtons";
import { useEffect, useState } from "react";
import { ServisGetInsert, ServisGetInsertProduct } from "../../services/InsertServis";
import InsertStore from "../../Stores/InsertStore";
import { ServisGetProductsIds } from "../../services/ProductServis";
import ProductCard from "../../components/ProductCard";
import PagesHeaderLinks from "../../components/PagesHeaderLinks";
import LanguageStore from "../../Stores/LanguageStore";
import ProductStore from "../../Stores/ProductStore";
import MainStore from "../../Stores/MainStore";
import { CampainFilter } from "../../components/CampainFilter";

function SearchPage() {
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        try {
            const fetchData = async () => {
                try {


                    setIsloading(false);
                } catch (error) {
                    console.error("Error in useEffect:", error);
                }
            };

            fetchData();
        } catch {
            console.log("mainhome screeen failed")
        }
    }, [LanguageStore.language, ProductStore.getProduct(), MainStore.getIsLoading()]);

    return (
        <>
            <SearchBar />
            <NavbarButtons />
            <div className="container">
                <PagesHeaderLinks text={i18n.t("Urunler")} />
                <div className="row">
                <CampainFilter />
                    <div className="col-12 col-md-9">
                        <div className="row mt-5 mt-md-5">
                            <div className="col-12 col-md-8">
                                {i18n.t("buguneozelkampanyalar")}
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                {i18n.t("CokSatanlar")}
                            </div>
                            <div className="row mt-5 ">
                                {
                                    isLoading ?
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border  text-danger" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        :
                                        ProductStore.getProduct()?.map((item) => {
                                            return (
                                                <>
                                                    <div className="col-6 col-md-3">
                                                        <ProductCard product={item} />
                                                    </div>
                                                </>
                                            )
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

const ObserverSearchPage = observer(SearchPage);
export default ObserverSearchPage;