import { useEffect } from "react"
import { IProduct } from "../Models/IProduct"
import FavoriteStore from "../Stores/FavoriteStore"
import { favoritedefault, favoritefull } from "../config/svgs"
import { convertSVGToDataURI } from "./svgtoImage"
import LanguageStore from "../Stores/LanguageStore"
import { IFavorite } from "../Models/IFavorite"
import { ServisAddFavorites, ServisDeleteFavorites } from "../services/FavoriteServis"
import { observer } from "mobx-react"
import { TokenCheck } from "./TokenCheck"
import { useNavigate } from "react-router"

interface favicon {
    faviconfoto?: string
    product: IProduct
}
function FavoriteIcon(product: favicon) {
    const navigate = useNavigate();
    const favoriteProducts = FavoriteStore.getFavorite() || [];
    const isFavorite = favoriteProducts.some(f => f.productId === product.product.productId);
    useEffect(() => {
        console.log("FavoriteIcon useEffect")
        
        async function start() {
         
        }
        start();
    
    
      }, [LanguageStore.language, FavoriteStore.getFavorite()]);
    
    function ActiveFavorite() {
        if (!TokenCheck()) {
            console.log("token bulunamadı")
            navigate("login")
            return
        }
        console.log("ActiveFavorite" + JSON.stringify(product));
        const favorite:IFavorite = {
            id: 0,
            memberId: 0,
            productId: product.product.productId,
            isActive: false
        }

        ServisDeleteFavorites(favorite).then(response => {
            console.log("ServisDeleteFavorites" + JSON.stringify(response))
            FavoriteStore.setFavorite(response)
        })

    }
    function DeActiveFavorite() {
        if (!TokenCheck()) {
            console.log("token bulunamadı")
            navigate("login")
            return
        }
        console.log("DeActiveFavorite" + JSON.stringify(product))
        const favorite:IFavorite = {
            id: 0,
            memberId: 0,
            productId: product.product.productId,
            isActive: false
        }

        ServisAddFavorites(favorite).then(response => {
            console.log("ServisAddFavorites" + JSON.stringify(response))
            FavoriteStore.setFavorite(response)
        })
        
    }

    return (
        <>
            <div className="row">
                <div className="col d-flex justify-content-end me-2 mt-2">
                    {
                        isFavorite ?
                            <img onClick={e => ActiveFavorite()} width={25} style={{cursor:"pointer"}} src={convertSVGToDataURI(favoritefull)} alt="Favorite" /> :
                            <img onClick={e => DeActiveFavorite()} width={25} style={{cursor:"pointer"}} src={convertSVGToDataURI(favoritedefault)} alt="Not Favorite" />
                    }
                </div>
            </div>
        </>
    );
}


const ObserverBasketPage = observer(FavoriteIcon);
export default ObserverBasketPage