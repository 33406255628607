import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import LanguageStore from '../../Stores/LanguageStore';


interface Item {
    id: number;
    title: string;
}

const items: Item[] = [
    { id: 1, title: 'Item 1' },
    { id: 2, title: 'Item 2' },
    { id: 3, title: 'Item 3' },
    { id: 4, title: 'Item 4' },
    { id: 5, title: 'Item 5' },
    { id: 6, title: 'Item 6' },
    { id: 7, title: 'Item 7' },
];

function CampainBanner() {
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        console.log("CampainBanner useEffect")
        //MainStore.setPageRefresh(!MainStore.getPageRefresh());

    }, [LanguageStore.language]);

    return (
        <>
            <div className="container mt-5" >
                <div className="row" >
                    <div className="col-12 col-md-3 mt-5 mt-md-0" >
                        <img src={require('../../assets/campain1.png')} />
                    </div>
                    <div className="col-12 col-md-3 mt-5 mt-md-0" >
                        <img src={require('../../assets/campain2.png')} />
                        </div>

                    <div className="col-12 col-md-3 mt-5 mt-md-0">
                    <img src={require('../../assets/campain3.png')} />
                    </div>

                    <div className="col-12 col-md-3 mt-5 mt-md-0" >
                    <img src={require('../../assets/campain4.png')} />
                    </div>
                </div>
            </div>
        </>
    );
}


const ObserverSCampainBanner = observer(CampainBanner);
export default ObserverSCampainBanner;
