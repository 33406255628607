import { observer } from "mobx-react";
import { convertSVGToDataURI } from "../../components/svgtoImage";
import { negative, plus, sepetresim } from "../../config/svgs";
import SearchBar from "../../components/SearchBar";
import NavbarButtons from "../../components/navbarButtons";
import { useEffect, useState } from "react";
import LanguageStore from "../../Stores/LanguageStore";
import MainStore from "../../Stores/MainStore";
import { ServisGetBasket } from "../../services/BasketServis";
import BasketStore from "../../Stores/BasketStore";
import { ServisGetBasketProducts, ServisGetProductIds } from "../../services/ProductServis";
import { getCurrencyIcon } from "../../components/getCurrencyIcon";
import { OrderSummery } from "../../components/OrderSummery";
import { Navigate, useNavigate } from "react-router";
import ProductCardHorizantal from "../../components/ProductCardHorizantal";
import PagesHeaderLinks from "../../components/PagesHeaderLinks";
import i18n from "../../config/languageConf";
import { ServisGetFavorites } from "../../services/FavoriteServis";
import FavoriteStore from "../../Stores/FavoriteStore";
import { AccountList } from "../../components/AccountList";
import ProductCard from "../../components/ProductCard";

function FavoritesPage() {
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(true);

    let teslimatucreti: number = 25
    let geneltoplam: number = 0
    let toplamindirim: number = 0
    let kdv: number = 0
    let aratoplam: number = 0


    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("BasketPage useEffect")
        async function start() {

            const favorites = await ServisGetFavorites()
            FavoriteStore.setFavorite(favorites)
            console.log(" favorites >" + JSON.stringify(FavoriteStore.getFavorite()))
            const favProducts = await ServisGetProductIds(favorites.map(m => m.productId))
            FavoriteStore.setFavoriteProducts(favProducts)
            setIsloading(false)
        }

        start()


    }, [LanguageStore.language, MainStore.getPageRefresh()]);


    return (
        <>
            <SearchBar />
            <NavbarButtons />
            <div className="container">
                <hr />
                <div className="col-12 d-flex justify-content-center flex-wrap">

                    {
                        isLoading ? <div className="col-12 d-flex justify-content-center">
                            <AccountList style3={{ color: "#E84B56" }} />
                            <div className="col-9 d-flex justify-content-center">
                                    <div className="spinner-border  text-danger" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                            </div>
                        </div>
                            :
                            <>
                                <div className="col-12 d-flex flex-wrap justify-content-between">

                                    <AccountList style3={{ color: "#E84B56" }} />


                                    {FavoriteStore.getFavoriteProducts().map((item) => {
                                        return (
                                            <>

                                                <ProductCard product={item} />
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                    }
                </div>


            </div>
        </>
    )
}

const ObserverFavoritesPage = observer(FavoritesPage);
export default ObserverFavoritesPage
function setIsloading(arg0: boolean) {
    throw new Error("Function not implemented.");
}
