import { observer } from "mobx-react";
import { IProduct } from "../Models/IProduct"
import FavoriteIcon from "./FavoriteIcon";
import { getCurrencyIcon } from "./getCurrencyIcon";
import { convertSVGToDataURI } from "./svgtoImage";
import { addbtnPruduct, negative, plus } from "../config/svgs";
import getImageUrl from "./getImage";
import BasketStore from "../Stores/BasketStore";
import incrementBtn from "./incrementBtn";
import decrementBtn from "./decrementBtn";
import { getBasketQuantity } from "./getBasketQuantity";
import { useNavigate } from "react-router";
import ProductStore from "../Stores/ProductStore";

type ProductCardType = {
    product: IProduct;
}

function ProductCard(product: ProductCardType) {
    const navigate = useNavigate();
    let basket = true;
    try {
        const bas = BasketStore.getBasket().filter((f) => f.product === product.product.productId)
        if (bas.length > 0) {
            basket = false;
        }
    } catch { }

    function godetail(product:IProduct){
        ProductStore.setSelectedProduct(product)
        navigate("/product/detail")
        
    }

    return (
        <div  className="item d-flex flex-column justify-content-between">
            <FavoriteIcon product={product.product} />
            <div className="row flex-grow-2">
                <div onClick={e => godetail(product.product)} className="col d-flex justify-content-center m-2">
                    <img width={50} height={50} src={getImageUrl(product.product)} alt="" />
                </div>
            </div>

            <div className="row">
                <div onClick={e => godetail(product.product)} className="col-12 d-flex justify-content-center m-2 text-wrap">
                    {product.product.product_name}
                </div>
            </div>

            <div className="row flex-grow-2">
                <div className="col d-flex justify-content-center m-2 text-red">
                    {product.product.product_unit[0]?.productPrices} {getCurrencyIcon()}
                </div>
            </div>
            {
                basket ?
                    <div onClick={e => {incrementBtn(product.product,e) }} style={{cursor:"pointer"}} className="row">
                        <div className="col d-flex justify-content-end mb-2 me-2">
                            <img src={convertSVGToDataURI(addbtnPruduct)} alt="" />
                        </div>
                    </div>
                    :
                    <div className="row d-flex justify-content-center">
                    <div className="col-12 mb-2">
                    <div className="col-12  d-flex justify-content-center">
                        <div className="prductcard-quantity-selector">
                            <div onClick={e => {decrementBtn(product.product, e)}} className="col-2"><img src={convertSVGToDataURI(negative)} id="decrement" /></div>
                            <div className="col-8"><b id="quantity">{getBasketQuantity(product.product)} {product.product.product_unit[0].description}</b></div>
                            <div onClick={e => {incrementBtn(product.product, e)}} className="col-2"><img src={convertSVGToDataURI(plus)} id="increment" /></div>
                        </div>
                    </div>
                    </div>
                </div>
            }
        </div>
    )
}

const ObserverProductCard = observer(ProductCard);
export default ObserverProductCard; 