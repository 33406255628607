import React, { useEffect, useState } from 'react';
import MainStore from '../Stores/MainStore';
import LanguageStore from '../Stores/LanguageStore';
import { observer } from 'mobx-react';
import { Dropdown } from 'react-bootstrap';
import i18n from '../config/languageConf';
import { TokenCheck } from './TokenCheck';
import MemberAdresStore from '../Stores/MemberAdresStore';
import { ServisGetMemberAdress } from '../services/MemberServis';
import { Navigate, useNavigate } from 'react-router';
import BasketStore from '../Stores/BasketStore';
import { ServisGetProduct, ServisGetProductIds, ServisGetSearch } from '../services/ProductServis';
import { ServisGetBasket } from '../services/BasketServis';
import ProductStore from '../Stores/ProductStore';


function SearchBar() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');

  useEffect(() => {
    console.log("SearchBar useEffect")
    async function start() {
      ServisGetMemberAdress().then(responseJson => {
        MemberAdresStore.setMemberAdress(responseJson)
      })
      if(TokenCheck()) {
        const basket = await ServisGetBasket()
        BasketStore.setBasket(basket)
        console.log("Basket > " + JSON.stringify(basket))
        console.log("Basket length > " + basket?.length)
        const product = await ServisGetProductIds(BasketStore.getBasket().map((m) => m.product))
        BasketStore.setBasketProducts(product)
        console.log("getBasketProducts > " + JSON.stringify(product))
      }
    }
    start();

   

  }, [LanguageStore.language, MainStore.getPageRefresh() ]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    MainStore.setIsLoading(true);
    setQuery(newQuery);
    navigate("/search")
    if (newQuery) {
      console.log("search word : " + newQuery);
      try{
        ServisGetSearch(newQuery).then(response => {
          console.log("search result : " + JSON.stringify(response))
          ProductStore.setProduct(response)
          MainStore.setIsLoading(false);
        })
      }catch(err) {
        console.log("search error : " + err)
      }
      
    }
    else{
      ServisGetProduct(1,50).then(response => {
        navigate("/search")
        console.log("search result : " + JSON.stringify(response))
        ProductStore.setProduct(response)
        MainStore.setIsLoading(false);
      })
    }

    
  };

  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 col-md-8 d-flex justify-content-start mb-3 mb-md-0">
            <div className="input-group">
              <input value={query} onChange={handleInputChange} className="form-control border-end-0 border"  type="search" placeholder={i18n.t("urunArama")} id="example-search-input" />
              <span className="input-group-append">
                <button className="btn btn-outline-secondary bg-white border-start-0 border-bottom-0 border" type="button">
                  <i className="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>

          <div className="col-12 col-md-2 d-flex justify-content-start mb-3 mb-md-0">
            <Dropdown id='selepetim'>
              <Dropdown.Toggle id='selepetim' variant="success">
                <i className="fa-sharp fa-solid fa-location-dot"></i> {i18n.t("adresSec")}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {
                  TokenCheck() ?
                    <>
                      {
                        MemberAdresStore.getMemberAdress()?.map((item, index) => {
                          return (
                            <Dropdown.Item key={index}>{item.adresTitle}</Dropdown.Item>
                          )
                        })
                      }
                    </>
                    :
                    <></>
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="col-12 col-md-2 d-flex justify-content-md-end align-items-center justify-content-center">

            <Dropdown id='selepetim'>
              <Dropdown.Toggle onClick={e => { navigate("/basket") }} id='selepetim' variant="success">
                <i className="fa-solid fa-basket-shopping me-1">
                  {
                    BasketStore.getBasketProducts()?.length > 0 ? <span className="badge bg-danger sepetimbtn mt-0">{BasketStore.getBasketProducts()?.length}</span> : null
                  }
                </i>
                {i18n.t("Sepetim")}
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>

      </div>
    </>
  );
}


const ObserverSearchBarScreen = observer(SearchBar);
export default ObserverSearchBarScreen;
