import React, { useEffect } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import { observer } from "mobx-react";
import { Dropdown } from 'react-bootstrap';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import PagesHeaderLinks from '../../components/PagesHeaderLinks';

function StoreAdressPage() {

  useEffect(() => {

    const pathname = new URL(window.location.href).pathname;
    const pathParts = pathname.split("/");
    const openValue = pathParts[pathParts.length - 1];

    console.log("OrderPage openValue > " + openValue);

    async function fetchData() {
      try {


      } finally {

      }

    }

    fetchData();  // Veri yükleme işlemi başlat
  }, [LanguageStore.language]);
  return (
    <>
      <SearchBar />
      <NavbarButtons />
      <div className="container">
        <PagesHeaderLinks text={i18n.t("Mağazalar")} />
        <div className="row mt-5">
          <div className="col-12" style={{ fontWeight: "bold" }}>{i18n.t("magazalarimiz")}</div>
          <div className="col-12">{i18n.t("enyakinmagaza")}</div>
        </div>
        <div className="row mt-2">

          <div className="row ">

            <div className="col-12 col-md-6 ">
              <div className="row mt-3">
                <div className="col-12 d-flex justify-content-between flex-wrap">
                  <div className="col-12 col-md-6 ">

                    <Dropdown >
                      <Dropdown.Toggle variant="success" id="dropdown-stores" >
                        {i18n.t("sehir")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="col-12 col-md-6 ">

                    <Dropdown >
                      <Dropdown.Toggle variant="success" id="dropdown-stores" >
                        {i18n.t("ilce")}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>


              <div className="col-12 mt-3" style={{ fontWeight: "bold" }}>{i18n.t("toplammagaza")}
                <div className="col-12 mt-3 ">
                  <div className="row">
                    <div className="col border rounded border-2  ">
                      <div className="row mt-2">
                        <div className="col-6" style={{ fontWeight: "bold" }}>BAĞCILAR SİPARİŞ</div>
                        <div className="col-6 d-flex justify-content-end mt-3"><i className="fa-solid fa-arrow-right"></i></div>
                        <div className="col-6 mb-1" style={{ color: "gray" }}>Samsun Cd. Bornova Sok No:66</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 ">
                  <div className="row">
                    <div className="col border rounded border-2  ">
                      <div className="row mt-2">
                        <div className="col-6" style={{ fontWeight: "bold" }}>BAĞCILAR SİPARİŞ</div>
                        <div className="col-6 d-flex justify-content-end mt-3"><i className="fa-solid fa-arrow-right"></i></div>
                        <div className="col-6 mb-1" style={{ color: "gray" }}>Samsun Cd. Bornova Sok No:66</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 ">
                  <div className="row">
                    <div className="col-12 border rounded border-2  ">
                      <div className="row mt-2">
                        <div className="col-6" style={{ fontWeight: "bold" }}>BAĞCILAR SİPARİŞ</div>
                        <div className="col-6 d-flex justify-content-end mt-3"><i className="fa-solid fa-arrow-right"></i></div>
                        <div className="col-6 mb-1" style={{ color: "gray" }}>Samsun Cd. Bornova Sok No:66</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 ">
                  <div className="row">
                    <div className="col-12 border rounded border-2  ">
                      <div className="row mt-2">
                        <div className="col-6" style={{ fontWeight: "bold" }}>BAĞCILAR SİPARİŞ</div>
                        <div className="col-6 d-flex justify-content-end mt-3"><i className="fa-solid fa-arrow-right"></i></div>
                        <div className="col-6 mb-1" style={{ color: "gray" }}>Samsun Cd. Bornova Sok No:66</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 ">
                  <div className="row">
                    <div className="col-12 border rounded border-2  ">
                      <div className="row mt-2">
                        <div className="col-6" style={{ fontWeight: "bold" }}>BAĞCILAR SİPARİŞ</div>
                        <div className="col-6 d-flex justify-content-end mt-3"><i className="fa-solid fa-arrow-right"></i></div>
                        <div className="col-6 mb-1" style={{ color: "gray" }}>Samsun Cd. Bornova Sok No:66</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 ">
                  <div className="row">
                    <div className="col-12 border rounded border-2  ">
                      <div className="row mt-2">
                        <div className="col-6" style={{ fontWeight: "bold" }}>BAĞCILAR SİPARİŞ</div>
                        <div className="col-6 d-flex justify-content-end mt-3"><i className="fa-solid fa-arrow-right"></i></div>
                        <div className="col-6 mb-1" style={{ color: "gray" }}>Samsun Cd. Bornova Sok No:66</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-3 ">
                  <div className="row">
                    <div className="col-12 border rounded border-2  ">
                      <div className="row mt-2">
                        <div className="col-6" style={{ fontWeight: "bold" }}>BAĞCILAR SİPARİŞ</div>
                        <div className="col-6 d-flex justify-content-end mt-3"><i className="fa-solid fa-arrow-right"></i></div>
                        <div className="col-6 mb-1" style={{ color: "gray" }}>Samsun Cd. Bornova Sok No:66</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-6 mt-3'>
              <img style={{ width: "100%" }} src={require('../../assets/storesmap.png')} />
            </div>
          </div>
        </div>


      </div>
    </>

  );
}

const ObserverStoreAdressPage = observer(StoreAdressPage);
export default ObserverStoreAdressPage;

