import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import MainStore from '../Stores/MainStore';
import LanguageStore from '../Stores/LanguageStore';
import { observer } from 'mobx-react';
import logo from '../assets/img/logo_navbar.png';
import i18n from '../config/languageConf';
import { ServisGetProductCategories, ServisGetSubCategories } from '../services/CategoryServis';
import CategoriesStore from '../Stores/CategoriesStore';
import BasketStore from '../Stores/BasketStore';


function NavbarButtons() {
  const [isActive, setIsActive] = useState("");

  useEffect(() => {
    console.log("NavigationBar useEffect")
    
    async function start() {
      ServisGetSubCategories().then(responseJson => {
        console.log("ServisGetSubCategories > " + responseJson)
        CategoriesStore.setProductCategories(responseJson)
      })
    }
    start();

     const currentUrl = window.location.pathname;
     console.log("NavigationBar"+currentUrl)
     setIsActive(currentUrl);

  }, [LanguageStore.language, MainStore.getPageRefresh(), BasketStore.getBasket()]);

  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-2">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <i className='fa-solid fa-bars'></i> {i18n.t("Kategoriler").toUpperCase()}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
                {
                  CategoriesStore.getProductCategories()?.map((item, index) => {
                    return (
                      <Dropdown.Item key={index}>{item.productCategoryName}</Dropdown.Item>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="col-12 col-md-2">
            <Link className={`custom-button ${isActive =="/campains" ? 'active' : ''}`} style={{ textDecoration: "none" }} to="/campains">{i18n.t("Kampanyalar").toUpperCase()}</Link>
          </div>
          <div className="col-12 col-md-2">
            <Link className={`custom-button ${isActive =="/stores" ? 'active' : ''}`} style={{ textDecoration: "none" }} to="/stores">{i18n.t("Mağazalar").toUpperCase()}</Link>
          </div>
          <div className="col-12 col-md-2">
            <Link className={`custom-button ${isActive =="/categories" ? 'active' : ''}`} style={{ textDecoration: "none" }} to="/categories">
              {i18n.t("Kategoriler").toUpperCase()}</Link>
          </div>
          <div className="col-12 col-md-2">
            <Link className={`custom-button ${isActive =="/contact" ? 'active' : ''}`} style={{ textDecoration: "none" }} to="/contact">
              {i18n.t("iletisim").toUpperCase()}</Link>
          </div>
          <div className="col-12 col-md-2 d-flex justify-content-end">

            <Link className={`custom-button ${isActive =="/order" ? 'active' : ''}`} style={{ textDecoration: "none" }} to="/order">
              {i18n.t("SiparisOzeti").toUpperCase()}<i className="fas fa-chevron-right ms-1"></i></Link>
          </div>
        </div>
      </div>


    </>
  );
}


const ObserverNavbarButtonsScreen = observer(NavbarButtons);
export default ObserverNavbarButtonsScreen;
