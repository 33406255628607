import { useRef } from "react"
import BasketStore from "../Stores/BasketStore"
import i18n from "../config/languageConf"
import { order } from "../config/svgs"
import { getCurrencyIcon } from "./getCurrencyIcon"
import { useNavigate } from "react-router"


export function CampainFilter() {


    return (
        <>
            <div className="col-12 col-md-3 border rounded mt-5" style={{maxHeight:"500px"}}>
                <div className="col-12 mt-3" style={{ fontWeight: "bold" }}>
                    <h4>{i18n.t("Filtrele")}</h4>
                </div>
                <hr />
                <div className="col-12">
                    <label className='ms-2'>
                        <input type="checkbox" id="scales" name="scales" checked />
                        <span className='ms-2' style={{ color: "gray" }}> {i18n.t("stoktavar")}(120)</span>
                    </label>
                </div>
                <div className="col-12 mt-1">
                    <label className='ms-2'>
                        <input type="checkbox" id="scales" name="scales" checked />
                        <span className='ms-2' style={{ color: "gray" }}> {i18n.t("stoktayok")}(12)</span>
                    </label>
                </div>
                <hr />
                <div className="col-12" style={{ fontWeight: "bold" }}>
                    <h4>{i18n.t("Markalar")}</h4>
                </div>
                <div className="col-12 border rounded">                   
                        <div className="input-group">
                            <input style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("markaara")} />
                        </div>                   
                </div>
                <hr />
                <div className="col-12 mt-3" style={{fontWeight:"bold"}}>
                    <h4>{i18n.t("Kategoriler")}</h4>
                </div>
                <div className="col-12" style={{color:"gray"}}>{i18n.t("CategoryName")}</div>

            </div>

        </>
    )

}

function setShowModal(arg0: boolean) {
    throw new Error("Function not implemented.")
}
