import { makeAutoObservable } from "mobx";
import BasketStore from "./BasketStore";
import { ICity } from "../Models/ICity";
import { ITown } from "../Models/ITown";
import { ICountry } from "../Models/ICounty";

class GeoStore {
  City: ICity[] = [];
  Town: ITown[] = [];
  Country: ICountry[] = [];


  constructor() {
    makeAutoObservable(this)
  }

  getCity() {
    return this.City;
  }
  getTown() {
    return this.Town;
  }
  getCountry() {
    return this.Country;
  }

  setCity(City: ICity[]) {
    this.City = City;
  }
  setTown(Town: ITown[]) {
    this.Town = Town;
  }
  setCountry(Country: ICountry[]) {
    this.Country = Country;
  }

}

export default new GeoStore();