// src/Login.js
import React, { FormEvent, useEffect, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { LoginServis } from '../../services/Loginservis';
import { IAdminLogin } from '../../Models/ILogin';
import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import { ServisGetMember } from '../../services/MemberServis';
import MemberStore from '../../Stores/MemberStore';
import { Link } from 'react-router-dom';

const OrderSuccess = () => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("NavigationBar useEffect")


    }, [LanguageStore.language, MainStore.PageRefresh]);

    function handleSubmit(event: FormEvent<HTMLFormElement>): void {
        throw new Error('Function not implemented.');
    }

    return (
            <div className="row">
                <div className='col-12 md-col-6 mt-5 mb-5'>
                    <img width={"100%"} height={"40%"} src={require('../../assets/registerpage.png')} />
                    <div className="row d-flex justify-content-center successbox">
                        <div className="col-12 col-md-4 d-flex justify-content-center success-border ">
                            <div className="row d-flex justify-content-center mt-5">
                            <img className='col-4 col-md-2 mb-3  align-items-center' src={require('../../assets/success.png')} />
                            <div className="row d-flex justify-content-center mb-3">
                            <div className="col-12 col-md-10 d-flex justify-content-center" style={{fontWeight:"bold" }}>{i18n.t("siparisolustu")}</div>
                            </div>
                            <div className="row d-flex justify-content-center mb-3">
                            <div className="col-12 d-flex justify-content-center"><Link style={{ color: "#12C08B", fontWeight:"bold" }} to={'/account/myorders'}> {i18n.t("Siparişlerim")}</Link></div>
                            </div>
                            <div className="col-12 col-md-5 d-flex justify-content-center mb-5">
                                <button type="submit" onClick={e=> navigate("/")} className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                {i18n.t("alisverisedevam")}
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};
const ObserverOrderSuccess = observer(OrderSuccess);
export default ObserverOrderSuccess;
