import { title } from "process";

const tr = {
  welcome: "merhaba",
  AdresimeGelsinAdres:"Adresime Gelsin Adres, Bilgilerin",
  EnyakınTeslimat:"En yakın Teslimat",
  Cumartesi:"Cumartesi",
  Anasayfa:"Anasayfa",
  Kategoriler:"Kategoriler",
  Sepetim:"Sepetim",
  Kampanyalar:"Kampanyalar",
  Hesabım:"Hesabım",
  Siparişlerim:"Siparişlerim",
  Adreslerim:"Adreslerim",
  Favorilerim:"Favorilerim",
  Mağazalar:"Mağazalar",
  Kurumsal:"Kurumsal",
  Ayarlar:"Ayarlar",
  hesabim_1:"Hesabım",
  hesabim_2:"Siparişlerim",
  hesabim_3:"Adreslerim",
  hesabim_4:"Hediye Çeklerim",
  hesabim_5:"Favorilerim",
  hesabim_6:"Alışveriş Listem",
  hesabim_7:"Stok Bildirimi",
  hesabim_8:"Mağazalar",
  hesabim_9:"Kurumsal",
  hesabim_10:"Ayarlar",
  hesabim_11:"Çıkış Yap",
  OdemeSecimi:"Ödeme Seçimi",
  SiparisOzeti:"Sipariş Özeti",
  AraToplam:"Ara Toplam",
  Uygula:"Uygula",
  indirimKodu:'İndirim Kodu Kullan',
  hediyeCeki:"Hediye Çeki Kullan",
  KDV:"K.D.V",
  ToplamIndirim:"Toplam İndirim",
  TeslimatUcreti:"Teslimat Ücreti",
  GenelToplam:"Genel Toplam",
  Devam:"Devam",
  BirlikteAlinan:"Birlikte Alınan Ürünler",
  Teslimat:"Teslimat",
  OdemeB:"ÖDEME",
  TeslimatB:"TESLİMAT",
  AdresB:"ADRES",
  SanaUygunGun:"Sana uygun gün ve saat aralığını seç",
  day_1:"Pazartesi",
  day_2:"Salı",
  day_3:"Çarşamba",
  day_4:"Perşembe",
  day_5:"Cuma",
  day_6:"Cumartesi",
  day_7:"Pazar",
  SonrakiAdim:"Sonraki Adım",
  Adres: "Adres",
  YeniAdresEkle: "Yeni Adres Ekle",
  FaturaIcinAyniAdres:"Fatura için aynı adresi kullan",
  FaturaAdresiniSecin:"Fatura Adresini Seçin",
  YeniFaturaAdresi:"Yeni Fatura Adresi",
  TumunuGor:"Tümünü Gör",
  adresSec:"Adresini Seçin",
  TeslimatAdresin:"Teslimat Adresin",
  ziliCalma:"Zili Çalma",
  TemassizTeslimat:"Temassız Teslimat",
  TeslimatZamani:"Teslimat Zamanınız",
  RandevuluTeslimat:"Randevulu Teslimat",
  odemeYontemiSec:"Ödeme Yötemini Belirle",
  paymentTypeName_1:"Kapıda Nakit Ödeme",
  paymentTypeName_2:"Kapıda Kartla Ödeme",
  paymentTypeName_3:"Online Ödeme",
  paymentTypeName_4:"Havale / EFT",
  siparisOzeti:"Sipariş Özeti",
  siparisNotunuz:"Sipariş Notunuz",
  OnBilgilendirme:"Ön Bilgilendirme Koşulları'",
  okudumOnayliyorum:"nı okudum onaylıyorum",
  mesafeliSatis:"Masafeli satış sözleşmesi'",
  onaylıyorum:"ni okudum onaylıyorum",
  odemeyiTamamla:"Ödemeyi Tamamla",
  siparisDone:"Siparişiniz başarıyla gerçekleştirilmiştir.",
  alisVerisDevam:"ALIŞVERİŞE DEVAM ET",
  odemeAlinmistir:'ÖDEME ALINMIŞTIR',
  urunArama:'Ürün Arama',
  urunAra:'ürün ara...',
  filtrele:"Filtrele",
  gorunum:"Görünüm",
  sirala:"Sırala",
  oneCikanAramalar:"Öne Çıkan Aramalar",
  iletisimsetting:"İletişim tercihlerinizi dilediğiniz zaman yukarıdan değiştirebilirsiniz. Fırsatlardan yararlanmak, kampanyaları kaçırmamak için seçeneklerden herhangi birini aktif etmenizi öneririz.",
  iletisimeposta:"E-Posta İle iletişim",
  iletisimsms:"SMS İle İletişim",
  settings:'Ayarlar',
  diltercihi:'Dil Tercihini Seçiniz',
  onayMesaj:"Onay Mesajı",
  anasayfaYonlendirme:"Dil Değişimi için Anasayfaya yönlendiriliyorsunuz....",
  OK:"OK",
  iletisim:"İletişim",
  Iadeislemlerivesorularınızicin:"İade işlemleri ve sorularınız için",
  CagrıMerkezimiz:"Çağrı Merkezimiz",
  veya:"veya",
  WhatsappHattimiz:"Whatsapp Hattımız",
  ileiletisimegecebilirsiniz:"ile iletişime geçebilirsiniz.",
  MusteriHizmetleri:"Müşteri Hizmetleri",
  Hergun:"Her gün",
  Ad:'Ad',
  Soyad:'Soyad',
  EPosta:'E-Posta',
  CepTelefonu:'Cep Telefonu',
  DogumTarihi:"Doğum Tarihi",
  Hesabim:"Hesabım",
  EPostailetisimineizinveriyorum:"E-Posta iletişimine izin veriyorum",
  SMSgonderimineizinveriyorum:"SMS gönderimine izin veriyorum",
  Kaydet:"Kaydet",
  ParolamiDegistir:"Parolamı Değiştir",
  ParolaYenile:"Parola Yenile",
  EskiSifreniz:"Eski Şifreniz",
  Goster:"Göster",
  YeniSifreniz:"Yeni Şifreniz",
  YeniSifrenizTekrar:'Yeni Şifreniz Tekrar',
  Ay:'Ay',
  Gun:'Gün',
  Yil:"Yıl",
  Siparislerim:"Siparişlerim",
  BaslangicTarihi:"Başlangıç Tarihi:",
  BitisTarihi:"Bitiş Tarihi:",
  FaturaTarihi:"Fatura Tarihi",
  FaturaNo:"Fatura No",
  FaturaT:"Fatura T.",
  Ara:"Ara",
  HediyeCeklerim:"Hediye Çeklerim",
  Favorilereeklediginiz:"Favorilere eklediğiniz ürünleri bu alandan inceleyebilir,satın alabilirsiniz.",
  StokBildirimi:"Stok Bildirimi",
  SectiginizUrunler:"Seçtiğiniz ürünler yeniden stoklarda olduğunda bildirim alacaksınız.",
  Magazalar:"Mağazalar",
  TumMagazalar:"Tüm Mağazalar",
  YakinimdakiMagazalar:"Yakınımdaki Mağazalar",
  Aramayapin:"Arama yapın",
  Indirimler:"İndirimler",
  Sec:"Seç",
  Markalar:"Markalar",
  UrunleriGoruntule:"Ürünleri Görüntüle",
  Filtrele:"Filtrele",
  Gorunum:"Görünüm",
  KartSeklinde:"Kart Şeklinde",
  ListeSeklinde:"Liste Şeklinde",
  IndirimMiktarinaGore:"İndirim Miktarına Göre",
  OnceYuksekFiyat:"Önce Yüksek Fiyat",
  OnceDusukFiyat:"Önce Düşük Fiyat",
  IndirimYuzdesineGore:"İndirim Yüzdesine Göre",
  SatinAldiklarimaGore:"Satın Aldıklarıma Göre",
  CokSatanlar:"Çok Satanlar",
  Onerilenler:"Önerilenler",
  Sirala:"Sırala",
  INDIRIM:"İNDİRİM",
  Adet:"Adet",
  Dashboard:"Dashboard",
  TOTALSALES:"TOPLAM SATIŞLAR",
  SvgListesi:"Svg Listesi",
  ProductListesi:"Ürün Listesi",
  SiparisListesi:"Sipariş Listesi",
  KullaniciListesi:"Kullanıcı Listesi",
  TeslimatAyarlari:"Teslimat Ayarları",
  OdemeAyarlari:"Ödeme Ayarları",
  Totalsalesorder:"Toplam satış siparişi",
  Avgsalesperorder:"Sipariş başına ortalama satış",
  comparetolastweek:"son hafta karşılaştırması",
  CategoryName:"Kategori Adı",
  ProductName:"Ürün Adı",
  Urunler:"Ürünler",
  ProductCode:"Ürünler Kodu",
  Aciklama:"Açıklama",
  urunEkleGuncelle:"Ürün Ekle / Güncelle",
  durum:"Durum",
  evet:"Evet",
  hayir:"Hayır",
  satisDurumu:"Satış Durumu",
  MainGroup:"Main Group",
  resimler:"Resimler",
  fiyatlar:"Fiyatlar",
  units:"Birim",
  UnitDescription:"Birim Tanımı",
  Factor:"Factor",
  Weiqht:"Weiqht",
  Width:"Width",
  Lenqth:"Lenqth",
  Heiqht:"Heiqht",
  Tare:"Tare",
  Add:"Ekle",
  type:"Type",
  price:"Price",
  priceType:"Price Type",
  productUnit:"Product Unit",
  PriceAdd:"Price Add",
  Guncelle:"Güncelle",
  close:"Kapat",
  category:"Kategori",
  unitCode:"Unit Code",
  UnitAdd:"Birim Ekle",
  siparisId:'Sipariş Id',
  sipariszamani:'Sipariş Zamanı',
  siparisveren:'Sipariş Veren',
  siparisadresi:'Sipariş Adresi',
  siparisdurumu:'Sipariş Durumu',
  siparistutar:'Tutar',
  detay:"Detay",
  yazdir:"Yazdır",
  kullaniciId:'Kullanıcı Id',
  kullaniciAdi:'Kullanıcı Adı',
  KullaniciSoyad:'Kullanıcı Soyad',
  KullaniciMail:'Kullanıcı Mail',
  FiyatTipi:'Fiyat Tipi',
  Kullanicilar:"Kullancilar",
  name:"Ad",
  surname:"Soyad",
  email:"Email",
  phone:"Phone",
  password:"Password",
  KullanciEkleGuncelle:"Kullancı Ekle / Güncelle",
  ErpCode:"erpCode",
  MemberEdit:"Member Edit",
  isActive:"Active",
  duplicate_email:"Tekrar Eden Email",
  newMember:"Yeni Kullanıcı",
  LastOrderedUsers:"Son Sipariş Veren Kullanıcılar",
  Kategori:"Category",
  urunAdi:"Ürün Adı",
  OrderEdit:"Sipariş Düzenleme",
  sil:"Sil",
  edit:"Düzenle",
  kategoriGuncellendi:"Kategori Güncellendi",
  KampanyaAdi:"Kampanya Adı",
  indirimtip:"İndirim Tipi",
  kampanyalarGuncellendi:"Kampanyalar Güncellendi",
  kullaniciListesiGuncellendi:"Kullanici Listesi Güncellendi",
  siparistelefon:'Sipariş Telefon',
  tamamlanan:"Tamalanan",
  iptalEdilen:"İptal Edilen",
  acik:"Açık",
  tumu: "Siparişler",
  print:"Yazdır",
  DAILYPLATFORMRATE:"GÜNLÜK PLATFORM ORANI",
  comparetolastday:"son günle karşılaştırın",
  WEEKLYANALYTICS:"HAFTALIK ANALIZLER",
  fromprevious:"önceki 15 günden",
  title:"Başlık",
  taxNumber:"Vergi No",
  taxOffice:"Vergi Dairesi",
  isSelectedInvoice:"Aktif Fatura A.",
  isSelectedShipping:"Aktif Teslimat A.",
  code:"Code",
  guncelledi:"Güncellendi",
  MarklarEkleGuncelle:"Markakar / Güncelle",
  Marka:"Marka",
  teslimatgunleri:"Teslimat Günleri",
  Resimler:"Resimler",
  Resimli:"Resimli",
  Temizle:"Temizle",
  AktifOlanlar :"Aktif Olanlar",
  AktifOlmayanlar:"Aktif Olmayanlar",
  ProductCategories :"Ürün Kategorileri",
  YeniKategoriEklendi: "Yeni Kategori Eklendi",
  kategoriler1:"Meyve & Sebze",
  kategoriler2:"Et & Tavuk & Şarküteri",
  kategoriler3:"Süt & Süt Ürünleri",
  kategoriler4:"Kahvaltılık",
  kategoriler5:"Ekmek & Pastane",
  kategoriler6:"Dondurulmuş Ürünler",
  kategoriler7:"Yemeklik Malzemeler",
  kategoriler8:"Atıştırmalık",
  kategoriler9:"İçecek",
  kategoriler10:"Kişisel Bakım & Kozmetik",
  kategoriler11:"Anne-Bebek &Çocuk",
  kategoriler12:"Temizlik",
  kategoriler13:"Kağıt Ürünleri",
  kategoriler14:"Evcil Dostlar",
  kategoriler15:"Elektronik",
  kategoriler16:"Giyim & Ayakkabu & Aksesuar",
  kategoriler17:"Ev & Yaşam",
  yardim:"Yardım",
  yardim1:"Yasal Uyarılar",
  yardim2:"Güvenlik Politikası",
  yardim3:"Kullanım Koşulları",
  yardim4:"Kişisel Verilerin Korunması",
  yardim5:"Hakkımızda",
  yardim6:"Kurumsal",
  yardim7:"Yatırımcı İlişkileri",
  hesabim:"Hesabım",
  hesabim1:"Hesabım",
  hesabim2:"Siparişlerim",
  hesabim3:"Kullanıcı Bilgilerim",
  hesabim4:"Adreslerim",
  hesabim5:"Favori Ürünlerim",
  hesabim6:"Ödeme Yöntemlerim",
  hesabim7:"Fatura Bilgilerim",
  biziarayin:"Bizi Arayın 0 850 000 00 00  Bize Yazın:destek@bisiparis.de",
  footertext:"Mobil Uygulamamız ile Her an Yanınızdayız!",
  magazalarimiz:"Mağazalarımız",
  enyakinmagaza:"En yakındaki Bisipariş Mağazasını bulmak için il/ilçe seçiniz...",
  sehir:"Şehir",
  ilce:"İlçe",
  kaydol:"Kaydol",
  kesfet:"Keşfet",
  girisyap:"Giriş Yap",
  stoktayok:"Stokta Yok",
  stoktavar:"Stokta Var",
  markaara:"Marka Ara",
  buguneozelkampanyalar:"Bisipariş Bugüne Özel Kampanyalar",
  siparisolustu:"Siparişiniz Başarılı Bir şekilde Oluşturuldu.",
  alisverisedevam:"Alışverişe Devam Et",
  alisverisebasla:"Alışverişe Başla",
  degisikliklerikaydet:"Değişiklikleri Kaydet",
  hesapayarlari:"Hesap Ayarları",
  dil:"TR",
  urunnotu:"Ürün Notu Ekle",
  teslimatadreslerim:"Teslimat Adreslerim",
  teslimatadresiekle:"Teslimat Adresi Ekle",
  faturaadreslerim:"Fatura Adreslerim",
  son1ay:"Son 1 Ay",
  son1aysiparisbulunmuyor:"Son 1 ay içerisinde vermiş olduğunuz sipariş bulunmuyor. Şimdi sipariş vererek alışverişe başlayabilirsiniz.",
  degistir:"Değiştir",
  onayli:"ONAYLI",
  onaysiz:"ONAYLANMADI",
  sepetinizdeurunbulunmuyor:"Sepetinizde hiç ürün bulunmuyor!",
  logintext:"Lütfen sisteme kayıtlı telefon numaranız/mail adresinizi ve şifrenizi giriniz",
  telefonmail:"Telefon / Mail adresi",
  sifre:"Şifre",
  hesabinyoksa:"Henüz hesabın yoksa",
  kayitol:"Kayıt Ol!",
  registertext:"Lütfen sisteme bilgilerinizi eksiksiz ve tam olarak doldurunuz.",
  registerplaceholder1:"İsim Soyisim",
  registerplaceholder2:"Telefon No",
  sifretekrar:"Şifre Tekrar",
  zatenkayitliysan:"Zaten kayıtlıysan",
  zamanaralıgı:"Siparişinizi gönderilmesini istediğinin zaman aralığını seçin",
  teslimatadresi:"Teslimat Adresi",
  ucretsiz:"Ücretsiz",
  odemebilgileri:"Lütfen ödeme bilgilerinizi giriniz.",
  kartno:"Kart No",
  kartuzerindekiisim:"İsim Soyisim",
  sonkullanmatarihi:"Son Kullanma Tarihi",
  guvenlikkodu:"Güvenlik Kodu",
  devamet:"Devam Et",
  sepetozeti:"Sepet Özeti",
  toplamtutar:"Toplam Tutar",
  odenecektutar:"Ödenecek Tutar",
  urun:"Ürün",
  sepetiduzenle:"Sepeti Düzenle",
  hesabimisil:"Hesabımı Sil",
  hataliparolatekrari:"Hatallı parola tekrar",
  iletisimformu:"İletişim Formu",
  konu:"Konu",
  mesaj:"Mesaj",
  mahalle:"Mahalle",
  binano:"Bina No",
  katno:"Kat No",
  daire:"Daire",
  adrestarifi:"Adres Tarifi*",
  caddesokak:"Cadde/Sokak*",
  adresbasligi:"Adres Başlığı(Örn: Ev)",
  kisiselbilgiler:"Kişisel Bilgiler",
  adresbilgisi:"Adres Bilgileri",
  taptaze:"Taptaze Meyve ve Sebzeler!",
  adressilme:"Adres Silme",
  adressilmeonay:"Adresi silmek istediğinizden emin misiniz?",
  iletisimtercihi:"İletişim Tercihlerim",
  bilgilendirmeamacli:"Bisipariş.de'den kampanya ve bilgilendirme amaçlı",
  smsonay:"SMS almak istiyorum.",
  cagrionay:"telefon çağrısı almak istiyorum",
  epostaonay:"E-Posta almak istiyorum",
  musterihizmetleriiletisim:"Müşteri Hizmetleri İletişim",
  sepeteekle:"Sepete Ekle",
  detailpagetext:"Ürünün stok, fiyat ve kampanya bilgisi, teslimatı gerçekleştirecek mağazanın stok, fiyat ve kampanya bilgilerine göre belirlenmektedir.",
  urunaciklama:"Ürün Açıklaması",
  urunbilgisi:"Ürün Bilgisi",
  iadekosul:"İade Koşulları",
  urunaciklama1:"*%100 Doğal",
  urunaciklama2:"*GDO'suz",
  toplammagaza:"Toplamda 258 Mağaza Listeleniyor",
  KullaniciBilgileriDoğrulanamadı:"Kullanıcı Bigileri Doğrulamadı",

};
export { tr };