import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IFavorite } from "../Models/IFavorite";
import { IProduct } from "../Models/IProduct";

class FavoriteStore {
  @observable Favorite: IFavorite[] = [];
  @observable FavoriteProducts: IProduct[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getFavoriteProducts() {
    return this.FavoriteProducts
  }

  setFavoriteProducts(FavoriteProducts: IProduct[]) {
    this.FavoriteProducts = FavoriteProducts;
  }

  getFavorite() {
    return this.Favorite;
  }

  setFavorite(Favorite: IFavorite[]) {
    this.Favorite = Favorite;
  }
}

export default new FavoriteStore();