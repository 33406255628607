import { IAccountMenu } from "../Models/IAccountMenu";
import { ICity } from "../Models/ICity";
import { ICountry } from "../Models/ICounty";
import { ILoginRequest, ILoginResponse } from "../Models/ILogin";
import { IMemeberLogin } from "../Models/IMemberLogin";
import { IRegister } from "../Models/IRegister";
import { ITown } from "../Models/ITown";
import { Config_AccountMenu, Config_GetCity, Config_GetCountry, Config_GetTown, Config_Member_Login, Config_Member_Register_Link, Config_Token_Link, DEFAULT_LINK } from "./servisconfig";

export function ServisGetCountry(): Promise<ICountry[]> {
    var data = fetch(DEFAULT_LINK + Config_GetCountry, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetCountry Service Error: " + error);
    });
    return data;
}

export function ServisGetCity(country:number): Promise<ICity[]> {
    var data = fetch(DEFAULT_LINK + Config_GetCity + "?countryId="+country, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetCity Service Error: " + error);
    });
    return data;
}

export function ServisGetTown(city:number): Promise<ITown[]> {
    var data = fetch(DEFAULT_LINK + Config_GetTown + "?cityId="+city, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetTown Service Error: " + error);
    });
    return data;
}