import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import MainStore from '../Stores/MainStore';
import LanguageStore from '../Stores/LanguageStore';
import { observer } from 'mobx-react';
import logo from '../assets/img/logo_navbar.png';
import i18n from '../config/languageConf';
import { TokenCheck } from './TokenCheck';
import MemberStore from '../Stores/MemberStore';
import { ServisGetMember } from '../services/MemberServis';
import MemberAdresStore from '../Stores/MemberAdresStore';
import BasketStore from '../Stores/BasketStore';
import turkeyImage from '../assets/turkey.png';
import germanImage from '../assets/german.png';
import englandImage from '../assets/englang.png';



function NavigationBar() {

  const navigate = useNavigate();
  const [defaultLang, setDefaultLang] = useState({ text: '', image: '' })
  
  const turkish = () => {
    console.log("TR");
  }
  const english = () => {
    console.log("ENG");
  }
  const german = () => {
    console.log("DE");
  }
  
  useEffect(() => {

    console.log("NavigationBar useEffect")
    ServisGetMember().then(member => {
      MemberStore.setMember(member)
      console.log("Member" + JSON.stringify(member, null, 2))
    })
    if (LanguageStore.language == "tr") {
      setDefaultLang({ text: "TR", image: turkeyImage })

    }
    else if (LanguageStore.language === "en") {
      setDefaultLang({ text: "ENG", image: englandImage })
    }
    else if (LanguageStore.language == "de") {
      setDefaultLang({ text: "DE", image: germanImage })
    }

  }, [LanguageStore.language, MainStore.getPageRefresh()]);

  function Logout() {
    localStorage.removeItem("token")
    MemberAdresStore.setMemberAdress([])
    BasketStore.setBasket([])
    BasketStore.setBasketProducts([])
    MainStore.setPageRefresh(!MainStore.getPageRefresh());
    navigate("/")
  }


  //@ts-ignore
  const handleSelect = (eventKey, event) => {
    const selectedText = event.target.textContent;
    const selectedImg = eventKey === "en" ? englandImage : eventKey === "de" ? germanImage : turkeyImage;
    setDefaultLang({ text: selectedText, image: selectedImg })
    console.log(`Selected item: ${eventKey}`);
    console.log(`Selected Img: ${selectedImg}`);
    LanguageStore.setLanguage(eventKey === "en" ? "en" : eventKey === "de" ? "de" : "tr")
 
    i18n.locale = LanguageStore.language;
    
    console.log("language conf > " + LanguageStore.language + " locale " + i18n.locale);
  };

  return (
    <>
      <div className="conteiner">
        <div className="row">
          <div className="col-12">
            <div className="navbar-horizontal-line"></div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row d-flex justify-content-start">
            <div className="col-12 col-md-6">
              <img src={logo} onClick={e => {
                navigate("/")
              }} alt="Logo" className="img-fluid" />
            </div>
            {
              TokenCheck() ?
                <>
                  <div className="col-12 col-md-6 d-flex justify-content-end">
                    <div className="col-12 col-md-10 mt-5 mt-md-0 d-flex justify-content-end">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i className="fa-solid fa-user me-1"></i> {MemberStore.getMember()?.name} {MemberStore.getMember()?.surname}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ maxHeight: "350px", overflowY: "auto", overflowX: "hidden" }}>
                          {/* <Dropdown.Item onClick={e => {navigate("/")}}> <i className="fa-solid fa-user me-1"></i> {i18n.t("Hesabım")}</Dropdown.Item> */}
                          <Dropdown.Item onClick={e => { navigate("/account/myadress") }}><i className="fa-solid fa-location-dot me-1"></i>{i18n.t("Adreslerim")}</Dropdown.Item>
                          <Dropdown.Item onClick={e => { navigate("/account/myorders") }}><i className="fa-solid fa-receipt me-1"></i>{i18n.t("Siparişlerim")}</Dropdown.Item>
                          <Dropdown.Item onClick={e => { navigate("/account/mysetting") }} ><i className="fa-solid fa-gear me-1"></i>{i18n.t("Ayarlar")}</Dropdown.Item>
                          <Dropdown.Item onClick={Logout}> <i className="fa-solid fa-right-from-bracket me-1"></i>{i18n.t("hesabim_11")}</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-12 col-md-2 mt-5 ms-2 mt-md-0">
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="success" id="dropdown-lang" style={{ fontWeight: "bold" }}>
                          {defaultLang.image && <img src={defaultLang.image} alt="Selected" style={{ width: '20px', marginRight: '10px' }} />}
                          {defaultLang.text}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ maxHeight: "200px", maxWidth: "100px", overflowY: "auto", overflowX: "hidden" }}>
                          {/* <Dropdown.Item onClick={e => {navigate("/")}}> <i className="fa-solid fa-user me-1"></i> {i18n.t("Hesabım")}</Dropdown.Item> */}
                          <Dropdown.Item eventKey="en" data-image={englandImage} onClick={english}><img src={englandImage} alt="" /> ENG</Dropdown.Item>
                          <Dropdown.Item eventKey="de" data-image={germanImage} onClick={german}><img src={germanImage} alt="" /> DE</Dropdown.Item>
                          <Dropdown.Item eventKey="tr" data-image={turkeyImage} onClick={turkish}><img src={turkeyImage} alt="" /> TR </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                </>

                :
                <>
                  <div className="col-12 col-md-6 d-flex justify-content-end mt-5 mt-md-0">
                    <Link className="kaydol-btn me-2 button" to={"/register"}>{i18n.t("kaydol")}</Link>
                    <Link className="girisyap-btn me-3 button" to={"/login"}>{i18n.t("girisyap")}</Link>
                    <div className="col-12 col-md-2 mt-5 ms-2 mt-md-0">
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle variant="success" id="dropdown-lang" style={{ fontWeight: "bold" }}>
                          {defaultLang.image && <img src={defaultLang.image} alt="Selected" style={{ width: '20px', marginRight: '10px' }} />}
                          {defaultLang.text}
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ maxHeight: "200px", maxWidth: "100px", overflowY: "auto", overflowX: "hidden" }}>
                          {/* <Dropdown.Item onClick={e => {navigate("/")}}> <i className="fa-solid fa-user me-1"></i> {i18n.t("Hesabım")}</Dropdown.Item> */}
                          <Dropdown.Item eventKey="en" data-image={englandImage} onClick={english}><img src={englandImage} alt="" /> ENG</Dropdown.Item>
                          <Dropdown.Item eventKey="de" data-image={germanImage} onClick={german}><img src={germanImage} alt="" /> DE</Dropdown.Item>
                          <Dropdown.Item eventKey="tr"  data-image={turkeyImage} onClick={turkish}><img src={turkeyImage} alt="" /> TR </Dropdown.Item>


                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </>
            }

          </div>
        </div>
      </div>



    </>
  );
}


const ObserverNavigationBarScreen = observer(NavigationBar);
export default ObserverNavigationBarScreen;
