import { useEffect, useState } from "react"
import i18n from "../config/languageConf"
import MemberStore from "../Stores/MemberStore"
import LanguageStore from "../Stores/LanguageStore"
import MainStore from "../Stores/MainStore"

interface orderinter {
    yazi1: string,
    yazi2: string,
    yazi3?: string
    style1?: {}
    style2?: {}
    style3?: {}
    degistir?:string
    setDegister?:any
    handleSave?:void
}
export function MembershipInformation(order: orderinter) {
   
    const [degistirValue, setDegistirValue ] = useState<boolean>(true)
    useEffect(() => {
        console.log("NavigationBar useEffect")


    }, [LanguageStore.language, MainStore.PageRefresh]);
    function handleShow(): void {
        
        order.setDegister(order.degistir)
        setDegistirValue(!degistirValue)
        console.log(order.degistir)

    }

    function changephone(e:any): void {
        console.log(e.target.value)
        //@ts-ignore
        MemberStore.setMember({...MemberStore.getMember(), phone: e.target.value})
    }

   

    return (
        <>
            <div className="row">
                <div className="col-12 border rounded mb-3 mt-3 ">
                    <div className="row  d-flex justify-content-start mb-3">
                        <div className="col-12 d-flex justify-content-start mt-2" style={{ fontWeight: "bold" }}>{order.yazi1}</div>
                        <div className="col-12  d-flex justify-content-center mt-2">

                            {
                                order.degistir == "tel" ?
                                degistirValue ?
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}>{order.yazi2}</div> :
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}><input className="form-control" maxLength={11} type="number" value={MemberStore.getMember()?.phone} onChange={e => {
                                        MemberStore.setMember({...MemberStore.getMember(), phone: e.target.value})
                                    }} />
                                    </div>
                                    :null
                            }
                            {
                                order.degistir == "ad" ?
                                degistirValue ?
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}>{order.yazi2}</div> :
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}><input className="form-control" maxLength={11} type="text" value={MemberStore.getMember()?.name} onChange={e => {
                                        MemberStore.setMember({...MemberStore.getMember(), name: e.target.value})
                                    }} /></div>
                                    :null
                            }
                             {
                                order.degistir == "soyad" ?
                                degistirValue ?
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}>{order.yazi2}</div> :
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}><input className="form-control" maxLength={11} type="text" value={MemberStore.getMember()?.surname} onChange={e => {
                                        MemberStore.setMember({...MemberStore.getMember(), surname: e.target.value})
                                    }} /></div>
                                    :null
                            }
                            {
                                order.degistir == "eposta" ?
                                degistirValue ?
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}>{order.yazi2}</div> :
                                    <div className="col-6 d-flex justify-content-start" style={{ color: "gray" }}><input className="form-control" maxLength={11} type="email" value={MemberStore.getMember()?.email} onChange={e => {
                                        MemberStore.setMember({...MemberStore.getMember(), email: e.target.value})
                                    }} /></div>
                                    :null
                            }
                            <div onClick={e => handleShow()} className="col-6 d-flex justify-content-end" style={{ color: "red" }} >{i18n.t("degistir")}</div>
                        </div>

                        <div className="col-12 d-flex justify-content-end" style={order.style1}>{order.yazi3}</div>
                    </div>
                </div>
            </div>

        </>
    )

}