import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import LanguageStore from '../../Stores/LanguageStore';
import banner from '../../assets/sliderproductbannder.png';
import i18n from '../../config/languageConf';
import { ServisGetProduct } from '../../services/ProductServis';
import ProductStore from '../../Stores/ProductStore';
import { HeaderText } from '../MenuHeaderText';
import ProductCard from '../ProductCard';


function SliderProduct() {
    const listRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsloading] = React.useState(true);
    useEffect(() => {
        setIsloading(true)
        try {
            const fetchData = async () => {
                try {
                    setIsloading(true)
                    console.log("SliderProduct useEffect")
                    ServisGetProduct(1, 50).then(items => {
                        ProductStore.setProduct(items)
                        setIsloading(false);
                    })
                } finally {
                    //setIsloading(false);
                }
            }
            fetchData();
        } catch (error) {
            // Handle the error here
            console.error(error);
        }
    }, [LanguageStore.language]);

    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className="container">

                <HeaderText headerLeftText={i18n.t("taptaze")} headerRightText={i18n.t("TumunuGor")} />
                <div className="row mt-2">
                    <div className="col-12 col-md-4">
                        <img src={banner} />
                    </div>
                    <div className="col-12 col-md-8 justify-content-end mt-5 mt-md-0 ">
                        {
                            isLoading ?
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border  text-danger" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="item-list" ref={listRef}>
                                        {ProductStore.getProduct()?.map(item => {
                                            return (
                                                <div key={item.productId}>
                                                    <ProductCard product={item} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div>
                                        <div className="row justify-content-end">
                                            <div className="col-1 col-md-1 me-md-0 me-5">
                                                <button id='sliderdButton' onClick={scrollLeft}><i className='fas fa-chevron-left ms-1'></i></button>
                                            </div>
                                            <div className="col-1 col-md-1 me-md-0 me-5">
                                                <button id='sliderdButton' onClick={scrollRight}><i className='fas fa-chevron-right ms-1'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }


                    </div>
                </div>
            </div>
        </>
    );
}


const ObserverSliderProductScreen = observer(SliderProduct);
export default ObserverSliderProductScreen;


