import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Row } from 'react-bootstrap';
import MainStore from '../Stores/MainStore';
import LanguageStore from '../Stores/LanguageStore';
import { observer } from 'mobx-react';
import logo from '../assets/img/logo_navbar.png';
import i18n from '../config/languageConf';
import { googleplaybutton, iosstorebutton } from '../config/svgs';
import { convertSVGToDataURI } from './svgtoImage';


function Footer() {
  useEffect(() => {
    console.log("NavigationBar useEffect")
    //MainStore.setPageRefresh(!MainStore.getPageRefresh());

  }, [LanguageStore.language, MainStore.getPageRefresh()]);

  return (
    <div>

      <div className="container mt-5">
        <hr />
        <footer className="py-5">
          <div className="row">
            <div className="col mb-6">
              <h5 style={{ color: "red", fontWeight: "bold" }}>{i18n.t("Kategoriler")}</h5>
              <br />
              <ul className="nav flex-column">
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler1")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler2")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler3")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler4")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler5")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler6")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler7")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler8")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler9")} </li></Link>
                
              </ul>
            </div>
            <div className="col mb-6 " style={{ marginLeft: -15 }}>
              <h5><br /> </h5>
              <ul className="nav flex-column">
                <br />
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler10")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler11")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler12")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler13")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler14")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler15")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler16")} </li></Link>
                <Link to="/categories" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("kategoriler17")} </li></Link>
                
              </ul>
            </div>

            <div className="col mb-6">
              <h5 style={{ color: "red", fontWeight: "bold" }}>{i18n.t("yardim")}</h5> <br />
              <ul className="nav flex-column">
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">{i18n.t("yardim1")}</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">{i18n.t("yardim2")}</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">{i18n.t("yardim3")}</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">{i18n.t("yardim4")}</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">{i18n.t("yardim5")}</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">{i18n.t("yardim6")}</a></li>
                <li className="nav-item mb-2"><a href="#" className="nav-link p-0 text-body-secondary">{i18n.t("yardim7")}</a></li>
              </ul>
            </div>

            <div className="col mb-6">
              <h5 style={{ color: "red", fontWeight: "bold" }}> {i18n.t("hesabim")} </h5> <br />
              <ul className="nav flex-column">
                <Link to="/account/myadress" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("hesabim1")} </li></Link>
                <Link to="/account/myorders" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("hesabim2")} </li></Link>
                <Link to="/account/mysetting" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("hesabim3")} </li></Link>
                <Link to="/account/myadress" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("hesabim4")} </li></Link>
                <Link to="/favorites" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("hesabim5")} </li></Link>
                <Link to="/account/myadress" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("hesabim6")} </li></Link>
                <Link to="/account/myadress" className="nav-link p-0 text-body-secondary"><li className="nav-item mb-2">{i18n.t("hesabim7")} </li></Link>
                
              </ul>
            </div>

            <div className="col-md-3 offset-md-1 mb-3" style={{ fontWeight: "bold" }}>
              <h5 style={{ fontWeight: "bold" }}> {i18n.t("footertext")} </h5>
              <div className="row">
                <div className="col-6">
                  <a href="https://apps.apple.com/tr/app/bi-siparis-de/id6474970608" target="_blank" rel="noopener noreferrer">
                    <img onClick={e => { }} src={convertSVGToDataURI(iosstorebutton)} alt="" />
                  </a>

                </div>
                <div className="col-6">
                  <a href="https://play.google.com/store/apps/details?id=com.bisiparis" target="_blank" rel="noopener noreferrer">
                    <img onClick={e => { }} src={convertSVGToDataURI(googleplaybutton)} alt="" />
                  </a>

                </div>
              </div>
              <div className="row">
                <img src={require('../assets/footerimg.png')} />
              </div>
            </div>

          </div>
          <hr />
          <div>
            <div className="row">
              <div className='col-3'>
                <img src={require('../assets/img/logo_navbar.png')} />
              </div>
              <div className='col-6'>
                <p >{i18n.t("biziarayin")}</p>
              </div>
              <div className='col-3 d-flex justify-content-end'>
                <i className="fa-brands fa-facebook me-1"></i>
                <i className="fa-brands fa-square-instagram me-1"></i>
                <i className="fa-brands fa-linkedin me-1"></i>
                <i className="fa-brands fa-twitter me-1"></i>
              </div>

            </div>

          </div>
        </footer>
      </div>
    </div>
  );
}


const ObserverFooterBar = observer(Footer);
export default ObserverFooterBar;
