// src/Login.js
import React, { useEffect, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import { OrderSummery } from '../../components/OrderSummery';
import BasketStore from '../../Stores/BasketStore';
import { getCurrencyIcon } from '../../components/getCurrencyIcon';
import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';
import { ordergray, orderred, sepetresim } from '../../config/svgs';
import { convertSVGToDataURI } from '../../components/svgtoImage';
import MemberStore from '../../Stores/MemberStore';
import MemberAdresStore from '../../Stores/MemberAdresStore';
import { json } from 'node:stream/consumers';
import { ServisGetBasketProducts } from '../../services/ProductServis';
import { ServisGetDeliveryTimes, ServisGetWeekDays } from '../../services/SettingServis';
import DeliverySettingStore from '../../Stores/DeliverySettingStore';
import GeoStore from '../../Stores/GeoStore';
import { ServisGetCity, ServisGetCountry, ServisGetTown } from '../../services/GeoServis';
import PagesHeaderLinks from '../../components/PagesHeaderLinks';
import { IOrderResponse } from '../../Models/IOrderResponse';
import { ServisAddOrders } from '../../services/OrderServis';
import { GetCityName, GetTownName } from '../../common/GetCityAndTown';
import { ServisGetMemberAdress } from '../../services/MemberServis';

const OrderPages = () => {
    const navigate = useNavigate();
    const [number, setNumber] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [expiry, setExpiry] = useState<string>('');
    const [cvc, setCvc] = useState<string>('');
    const [focus, setFocus] = useState<string>('');
    const [seletedDay, setSeletedDay] = useState<number | null>(3);

    let teslimatucreti: number = 25
    let geneltoplam: number = 0
    let toplamindirim: number = 0
    let kdv: number = 0
    let aratoplam: number = 0

    useEffect(() => {
        window.scrollTo(0, 0)
        async function start() {
            console.log("OrderPages useEffect")
            console.log(MemberStore.getMember())
            console.log("Adress > " + MemberAdresStore.getMemberAdress())
            
            const memberadress= await ServisGetMemberAdress()
            MemberAdresStore.setMemberAdress(memberadress)

            const selectedAdress = MemberAdresStore.getMemberAdress().find(adress => adress.isSelectedShipping == true);
            console.log("selectedAdress1 > " + selectedAdress)

            if (selectedAdress) {
                console.log("Address > " + JSON.stringify(selectedAdress));
                MemberAdresStore.setSelectedAdress(selectedAdress);
            }

            await ServisGetCountry().then((t) => {
                GeoStore.setCountry(t)
            });
            //@ts-ignore
            await ServisGetCity(213).then((c) => {
                GeoStore.setCity(c)
            });
            
            await ServisGetTown(selectedAdress?.geoCityId as 6438).then((t) => {
                GeoStore.setTown(t)
            });



            await ServisGetBasketProducts(BasketStore.getBasket().map((m) => m.product)).then((products) => {
                BasketStore.setBasketProducts(products)
                BasketStore.getBasket().map((b) => {

                    const product = BasketStore.getBasketProducts().find(p => p.productId === b.product)
                    console.log("product > " + JSON.stringify(product))

                    if (product && product.product_unit && product.product_unit[0] && product.product_unit[0].productPrices) {

                        aratoplam += product.product_unit[0].productPrices;
                        console.log("product > " + JSON.stringify(aratoplam))

                    }
                })
                aratoplam = aratoplam //- (aratoplam / 100) * 20
                kdv = (aratoplam / 100) * 20
                geneltoplam = teslimatucreti + aratoplam + kdv
            })

            await ServisGetWeekDays().then((days) => {
                DeliverySettingStore.setWeekDays(days)
                console.log("days > " + JSON.stringify(days))
            })
            //@ts-ignore
            await ServisGetDeliveryTimes(seletedDay).then((times) => {
                console.log("ServisGetDeliveryTimes > " + JSON.stringify(times));
                if (Array.isArray(times)) {
                    const dayTimes = times.filter(t => t.dayId === seletedDay).filter(t => t.townId === 0);
                    console.log("dayTimes ServisGetDeliveryTimes > " + JSON.stringify(dayTimes));
                    DeliverySettingStore.setDeliveryTimes(dayTimes);
                } else {
                    console.error("Expected an array but got:", times);
                }
            }).catch(error => {
                console.error("Error fetching delivery times:", error);
            });
        }
        start()

    }, [LanguageStore.language, MainStore.PageRefresh, seletedDay]);
    try {
        if (!BasketStore.getBasket() || BasketStore.getBasket().length === 0) {

            console.log(BasketStore.getBasket())


        } else {
            BasketStore.getBasket().map((b) => {
                if (!BasketStore.getBasket()) {
                    //console.log(BasketStore.getBasket())
                } else {
                    //console.log(BasketStore.getBasket())
                    const product = BasketStore.getBasketProducts().find(p => p.productId === b.product)
                    if (product && product.product_unit && product.product_unit[0] && product.product_unit[0].productPrices) {
                        aratoplam += product.product_unit[0].productPrices;
                        //console.log("product > " + JSON.stringify(aratoplam))
                    }
                }
            })

            aratoplam = aratoplam //- (aratoplam / 100) * 20
            kdv = (aratoplam / 100) * 20
            geneltoplam = teslimatucreti + aratoplam + kdv
        }

    } catch (error) {
        //setBasketStatus(true)
    }
    const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        console.log("handle focus > " + e.target.name)
        setFocus(e.target.name);
    };



    function OrderSubmit() {
        console.log("OrderSubmit");
        let order:IOrderResponse ={
            id: 0,
            memberId: 0,
            situationId: 1,
            orderTime: new Date(),
            orderIp: '',
            orderTotal: 0,
            shippingAmount: 0,
            tck: '',
            shippingPersonName: '',
            shippingAddress: '',
            shippingCityId: MemberAdresStore.getMemberAdress().find(member => member.isSelectedShipping)?.geoCityId as 0,
            shippingTownId: MemberAdresStore.getMemberAdress().find(member => member.isSelectedShipping)?.geoTownId as 0,
            shippingDistId: 99,
            shippingPhone: '',
            billingPersonName: '',
            billingAddress: '',
            billingCityId: MemberAdresStore.getMemberAdress().find(member => member.isSelectedInvoice)?.geoCityId as 0,
            billingTownId: MemberAdresStore.getMemberAdress().find(member => member.isSelectedInvoice)?.geoTownId as 0,
            billingDistId: 99,
            billingPhone: '',
            billingTaxOffice: '',
            billingTaxNumber: '',
            orderNote: '1',
            orderCode: '1',
            deliveryTypeId: 1,
            deliveryTime: new Date(),
            deliveryStartHour: new Date(),
            deliveryEndHour: new Date(),
            storeId: 0,
            source: '',
            geoLat: '',
            geoLong: '',
            pickerUserId: 0,
            pickerDate: new Date(),
            basketIds: BasketStore.getBasket().map(ids => ids.id),
            paymentTypeId: 2,
        }

        ServisAddOrders(order).then(order => {
            navigate("/ordersuccess")
        }).catch(err => {
            console.error(err);
        });

    }

    return (
        <><SearchBar /><NavbarButtons />

            {
                BasketStore.getBasket()?.length > 0 ?

                    <div className="container">

                        <div className="row">

                            <div className="image-container" style={{ position: "relative", width: "220px" }}>
                                <img src={convertSVGToDataURI(ordergray)} alt="" style={{ width: "100%" }} />
                                <img src={convertSVGToDataURI(orderred)} alt="" style={{ width: "100%" }} />
                                <div className="text-over-image">
                                    <p style={{ position: "absolute", top: "11px", left: "50%", transform: "translateX(-50%)", color: "white", fontWeight: "bold" }}>TESLİMAT</p>
                                    <p style={{ position: "absolute", top: "11px", left: "144%", transform: "translateX(-50%)", color: "white", fontWeight: "bold" }}>ÖDEME</p>
                                </div>
                            </div>
                        </div>
                        <PagesHeaderLinks text={i18n.t("SiparisOzeti")} />
                        <div className="row ">
                            <div className='col-12 col-md-8 mt-5 mb-5'>
                                <div className='col-12 col-m-8 ' style={{ fontWeight: "bold" }} >{i18n.t("zamanaralıgı")}</div>
                                <div className='col-12 mt-3  ' >
                                    <div className="row  d-flex justify-content-around">
                                        {
                                            DeliverySettingStore.getWeekDays()?.map(day => {
                                                return (
                                                    <div style={{ cursor: "pointer" }} onClick={e => setSeletedDay(day.id)} className={`col-3 col-md-2 border rounded ${day.id == seletedDay ? 'border-danger' : 'border-success'}`}>
                                                        <div className="row ">
                                                            <div className="col-12 d-flex justify-content-center mb-1 mt-2" style={{ fontWeight: "bold" }}> {day.dayName}
                                                            </div>
                                                            <div className="col-12 d-flex justify-content-center mb-2" style={{ color: "gray" }}>30 Mayıs
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            DeliverySettingStore.getDeliveryTimes()?.map(time => {
                                                return (
                                                    <div className="row">
                                                        <div className='col-12  border mt-4 rounded ' >
                                                            <div className='col-12 d-flex justify-content-start mt-2 mb-2 align-items-center'>
                                                                <div className="form-check col-6">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1" style={{ fontWeight: "bold" }}>
                                                                        {time.startTime} - {time.finishTime}
                                                                    </label>
                                                                </div>
                                                                <div className="col-6 d-flex justify-content-end mt-2 mb-2" style={{ color: "#0CC550", fontWeight: "bold" }}>{i18n.t("ucretsiz")}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div>
                                    <div className="col-12 border rounded mt-4  d-flex  align-items-center">
                                        <div className="col-6 d-flex flex-column justify-content-start ">
                                            <div className="row orderborder rounded mb-2 ms-2">
                                                {i18n.t("teslimatadresi")}
                                            </div>
                                            <div className="col-12 ms-2"><i className="fa fa-map-pin"></i> {MemberAdresStore.SelectedAdress?.adresTitle} {MemberAdresStore.SelectedAdress?.adress} {GetCityName(MemberAdresStore.getMemberAdress().find(f => f.isSelectedShipping == true)?.geoCityId as 0)} / {GetTownName(MemberAdresStore.getMemberAdress().find(f => f.isSelectedShipping == true)?.geoCityId as 0,MemberAdresStore.getMemberAdress().find(f => f.isSelectedShipping == true)?.geoTownId as 0)}</div>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <a className="degistir-btn button">{i18n.t("degistir")}</a>
                                        </div>
                                    </div>
                                    <div className="col-12  d-flex  align-items-center justify-content-between mt-4">
                                        <div className="col-5 md-col-3 border rounded mt-2 justify-content-between d-flex ">
                                            <div className="form-check mt-3 mb-2 ">
                                                <div className="form-check form-switch">
                                                    <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault"> {i18n.t("ziliCalma")}</label>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-7 md-col-8 d-flex justify-content-between align-items-center border rounded mt-2">
                                            <div className="col-6 "><span style={{ marginLeft: 15 }}>{MemberStore.getMember()?.phone}</span></div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <a className="degistir-btn button">{i18n.t("degistir")}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-start mt-4" style={{ fontWeight: "bold" }} >{i18n.t("odemebilgileri")}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between mt-3">
                                            <div className="col-6 col-md-5 border rounded d-flex justify-content-center align-items-center">
                                                <div className="col-2 d-flex justify-content-between" style={{ color: "#E32835" }}>
                                                    <i className="fa fa-credit-card ms-3" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-10 " style={{ fontWeight: "bold" }}>
                                                    <div className="input-group">
                                                        <input style={{ border: "none", fontWeight: "bold" }} maxLength={16} type="tel" name='number' onFocus={handleInputFocus} className="form-control" placeholder={i18n.t("kartno")} onChange={e => {
                                                            setNumber(e.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-5 border rounded d-flex justify-content-center align-items-center">
                                                <div className="col-2" style={{ color: "#E32835" }}>
                                                    <i className="fa fa-user ms-3" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-10 " style={{ fontWeight: "bold" }}>
                                                    <div className="input-group">
                                                        <input style={{ border: "none", fontWeight: "bold" }} type="text" name='name' onFocus={handleInputFocus} className="form-control" placeholder={i18n.t("kartuzerindekiisim")} onChange={e => {
                                                            setName(e.target.value)
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-between mt-3">
                                        <div className="col-6 col-md-5 border rounded d-flex justify-content-center align-items-center">
                                            <div className="col-2" style={{ color: "#E32835" }}>
                                                <i className="fa fa-calendar ms-3" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-10 " style={{ fontWeight: "bold" }}>
                                                <div className="input-group">
                                                    <input style={{ border: "none", fontWeight: "bold" }} maxLength={3} name='expiry' onFocus={handleInputFocus} type="tel" className="form-control" placeholder={i18n.t("sonkullanmatarihi")} onChange={e => {
                                                        setExpiry(e.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-5 border rounded d-flex justify-content-center align-items-center">
                                            <div className="col-2" style={{ color: "#E32835" }}>
                                                <i className="fa fa-lock ms-3" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-10  d-flex justify-content-between" style={{ fontWeight: "bold" }}>
                                                <div className="input-group">
                                                    <input style={{ border: "none", fontWeight: "bold" }} name='cvc' onFocus={handleInputFocus} type="text" className="form-control" placeholder={i18n.t("guvenlikkodu")} onChange={e => {
                                                        setCvc(e.target.value);
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-start mt-3">
                                        <div className="credit-card-form">
                                            <Cards
                                                number={number}
                                                name={name}
                                                expiry={expiry}
                                                cvc={cvc}
                                                //@ts-ignore
                                                focused={focus}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 ">
                                <div className="row">
                                    <div className="col-12 border rounded mt-5">
                                        <OrderSummery yazi1={i18n.t("sepetozeti")} style1={{ fontWeight: "bold" }} yazi2={BasketStore.getBasketProducts()?.length + " " + i18n.t("urun")} style2={{ color: "gray" }} />
                                        <OrderSummery yazi1={i18n.t("toplamtutar")} yazi2={geneltoplam.toFixed(2) + " " + getCurrencyIcon()} />
                                        <OrderSummery yazi1={i18n.t("TeslimatUcreti")} yazi2={teslimatucreti.toString() + " " + getCurrencyIcon()} />
                                        <OrderSummery yazi1={i18n.t("ToplamIndirim")} yazi2={toplamindirim + " " + getCurrencyIcon()} />
                                        <OrderSummery yazi1="KDV" yazi2={kdv.toFixed(2) + " " + getCurrencyIcon()} />
                                        <OrderSummery yazi1={i18n.t("odenecektutar")} style1={{ fontWeight: "bold" }} yazi2={geneltoplam.toFixed(2) + " " + getCurrencyIcon()} style2={{ color: "#0CC550" }} />
                                        <hr />
                                        <div className='col-12 text-wrap '>
                                            <label className='ms-2'>
                                                <input type="checkbox" id="scales" name="scales" checked />
                                                <span className='ms-2' style={{ color: "#E4211F" }}>{i18n.t("OnBilgilendirme")}</span>
                                            </label>
                                            <span className='ms-2'>{i18n.t("okudumOnayliyorum")}</span>
                                        </div>
                                        <div className='col-12 text-wrap '>
                                            <label className='ms-2'>
                                                <input type="checkbox" id="scales" name="scales" checked />
                                                <span className='ms-2' style={{ color: "#E4211F" }}>{i18n.t("mesafeliSatis")}</span>
                                            </label>
                                            <span className='ms-2'>{i18n.t("onaylıyorum")}</span>
                                        </div>
                                        <div className="col-12 col-md-12 d-flex justify-content-center mb-2">
                                            <button type="submit" onClick={e => OrderSubmit()} className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                                {i18n.t("devamet")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12 border rounded d-flex flex-column">
                                        <div className="row align-items-center">
                                            <div className="col-1 mt-2"><i className="fa fa-pencil" aria-hidden="true"></i></div>
                                            <div className="col-11 mt-2" style={{ color: "#E4211F", fontWeight: "bold" }}>{i18n.t("siparisNotunuz")}</div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-12">
                                                <textarea className="border-0 w-100" rows={5} style={{ resize: "none" }}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="container">
                            <PagesHeaderLinks text={i18n.t("SiparisOzeti")} />

                            <div className="row mt-5 border border-1 rounded d-flex justify-content-center ">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center mb-3 mt-3" style={{ color: "gray" }}>{i18n.t("sepetinizdeurunbulunmuyor")}</div>
                                </div>
                                <div className="row">
                                    <div className="col-12  d-flex justify-content-center "><img src={convertSVGToDataURI(sepetresim)} alt="" /></div>
                                </div>
                                <div className="row">
                                    <div className="col-2 col-md-4 "></div>

                                    <div className="col-8 col-md-4  d-flex justify-content-center "><button onClick={e => { navigate("/") }} type="submit" className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                        {i18n.t("alisverisebasla")}
                                    </button>
                                    </div>
                                    <div className="col-2 col-md-4 "></div>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    );
};
const ObserverOrderPages = observer(OrderPages);
export default ObserverOrderPages;
