import React, { useEffect, useRef } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import { observer } from "mobx-react";
import { Dropdown } from 'react-bootstrap';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import PagesHeaderLinks from '../../components/PagesHeaderLinks';
import ProductDetailCampain from '../../components/ProductDetailCampain';
import ProductStore from '../../Stores/ProductStore';
import getImageUrl from '../../components/getImage';
import { IProduct } from '../../Models/IProduct';
import { getCurrencyIcon } from '../../components/getCurrencyIcon';
import FavoriteIcon from '../../components/FavoriteIcon';
import incrementBtn from '../../components/incrementBtn';
import ProductCard from '../../components/ProductCard';
import { ServisGetProduct } from '../../services/ProductServis';

function ProductDetailsPage() {
    const listRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsloading] = React.useState(true);
    useEffect(() => {
        
        const pathname = new URL(window.location.href).pathname;
        const pathParts = pathname.split("/");
        const openValue = pathParts[pathParts.length - 1];

        console.log("OrderPage openValue > " + openValue);

        async function fetchData() {
            try {
                try {
                    setIsloading(true)
                    console.log("SliderProduct useEffect")
                    ServisGetProduct(1, 50).then(items => {
                        ProductStore.setProduct(items)
                        setIsloading(false);
                    })
                } finally {
                    //setIsloading(false);
                }

            } finally {

            }

        }

        fetchData();  // Veri yükleme işlemi başlat
    }, [LanguageStore.language]);
    //@ts-ignore
    const imgurl:string = getImageUrl(ProductStore.getSelectedProduct())
    //@ts-ignore
    const s_product: IProduct = ProductStore.getSelectedProduct()
    function addsepet(product: IProduct) {
        incrementBtn(s_product,null)
    }
    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };

    return (
        <>
            <SearchBar />
            <NavbarButtons />
            <div className="container">
                <div className="row d-flex justify-content-between mt-4">
                    <div className="col-md-6 border rounded">
                        <div className="d-flex flex-column align-items-center">
                            <img src={imgurl} alt="Product Detail" width={200} />
                            <div className="row mt-2">
                                <div className="col-4 border rounded d-flex justify-content-center align-items-center">
                                    <img src={imgurl} width={100} alt="Product Detail" />
                                </div>
                                <div className="col-4 border rounded d-flex justify-content-center align-items-center">
                                    <img src={imgurl} width={100} alt="Product Detail" />
                                </div>
                                <div className="col-4 border rounded d-flex justify-content-center align-items-center">
                                    <img src={imgurl} width={100}  alt="Product Detail" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="col-md-12 border rounded d-flex flex-column ">
                            <div className='col-12 ms-3 mt-2'>
                                <h3>{ProductStore.getSelectedProduct()?.product_name}</h3>
                            </div>
                            <div className="col-12 ms-3" style={{ fontWeight: "bold" }}>{ProductStore.getSelectedProduct()?.product_unit[0].description} {ProductStore.getSelectedProduct()?.product_unit[0].factor}</div>
                            <div className="col-12 ms-3 mt-1" style={{ color: "#E32532" }}>{ProductStore.getSelectedProduct()?.product_unit[0]?.productPrices} {getCurrencyIcon()}</div>
                            <div className="col-12 d-flex flex-wrap ">
                                <div className="col-6">
                                    <div className="col-12 col-md-12 d-flex justify-content-center mb-2">
                                        <button onClick={e => {addsepet(s_product)}} type="submit" className="col-10 btn btn-danger btn-block mt-3 mb-2">
                                            {i18n.t("sepeteekle")}
                                        </button>
                                    </div>
                                </div>
                                <div className="col-1 mt-3">
                                    <FavoriteIcon product={s_product} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 border rounded mt-4  d-flex justify-content-center">
                            <div className="col-12 ms-3 mb-4 mt-4"> 
                            {i18n.t("detailpagetext")}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 border rounded mt-4">
                        <div className="col-5 d-flex justify-content-center mt-2">
                            <div className="col-4">{i18n.t("urunaciklama")}</div>
                            <div className="col-4">{i18n.t("urunbilgisi")}</div>
                            <div className="col-4">{i18n.t("iadekosul")}</div>
                            
                        </div>
                        <hr />
                        <div className="col-12">
                        {i18n.t("urunaciklama1")}
                        </div>
                        <div className="col-12 mb-3">
                        {i18n.t("urunaciklama2")}
                        </div>
                    </div>
                    <div className="col-12 mt-3" style={{fontWeight:"bold"}}> <h4>{i18n.t("buguneozelkampanyalar")}</h4></div>
                    <div className="col-12 d-flex flex-column">
                    <div className="col-12 justify-content-end mt-5 mt-md-0 ">
                        {
                            isLoading ?
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border  text-danger" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                                :
                                <div>
                                    <div className="item-list" ref={listRef}>
                                        {ProductStore.getProduct()?.map(item => {
                                            return (
                                                <div key={item.productId}>
                                                    <ProductCard product={item} />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div>
                                        <div className="row justify-content-end">
                                            <div className="col-1 col-md-1 me-md-0 me-5">
                                                <button id='sliderdButton' onClick={scrollLeft}><i className='fas fa-chevron-left ms-1'></i></button>
                                            </div>
                                            <div className="col-1 col-md-1 me-md-0 me-5">
                                                <button id='sliderdButton' onClick={scrollRight}><i className='fas fa-chevron-right ms-1'></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }


                    </div>
                    </div>
                </div>
            </div>



        </>

    );
}

const ObserverProductDetailsPage = observer(ProductDetailsPage);
export default ObserverProductDetailsPage;

