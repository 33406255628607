import { observer } from "mobx-react";
import i18n from "../config/languageConf";
import { useNavigate } from "react-router";
type pagespros = {
    text:string
}

function PagesHeaderLinks(pros:pagespros) {
    const navigate = useNavigate();

    return (
        <div className="row mt-5">
            <div className="col-12 col-md-4">
                <span  onClick={e => {navigate("/")}} style={{cursor:"pointer"}}>{i18n.t("Anasayfa")}</span> 
                <i style={{ marginLeft: "10px", marginRight: "10px" }} className="fas fa-chevron-right"></i>
                <span style={{color:"black", fontWeight:"bold"}}>{pros.text}</span>
            </div>
        </div>
    )
}

const ObserverPagesHeaderLinks = observer(PagesHeaderLinks);
export default ObserverPagesHeaderLinks;