import { observer } from "mobx-react";
import { convertSVGToDataURI } from "../../components/svgtoImage";
import { negative, plus, sepetresim } from "../../config/svgs";
import SearchBar from "../../components/SearchBar";
import NavbarButtons from "../../components/navbarButtons";
import { useEffect } from "react";
import LanguageStore from "../../Stores/LanguageStore";
import MainStore from "../../Stores/MainStore";
import { ServisGetBasket } from "../../services/BasketServis";
import BasketStore from "../../Stores/BasketStore";
import { ServisGetBasketProducts, ServisGetProductIds } from "../../services/ProductServis";
import { getCurrencyIcon } from "../../components/getCurrencyIcon";
import { OrderSummery } from "../../components/OrderSummery";
import { Navigate, useNavigate } from "react-router";
import ProductCardHorizantal from "../../components/ProductCardHorizantal";
import PagesHeaderLinks from "../../components/PagesHeaderLinks";
import i18n from "../../config/languageConf";

function BasketPage() {
    const navigate = useNavigate();

    let teslimatucreti: number = 25
    let geneltoplam: number = 0
    let toplamindirim: number = 0
    let kdv: number = 0
    let aratoplam: number = 0


    useEffect(() => {
        console.log("BasketPage useEffect")
        async function start() {
            const basket = await ServisGetBasket()
            BasketStore.setBasket(basket)
            console.log("Basket > " + JSON.stringify(basket))
            console.log("Basket length > " + basket.length)
            const product = await ServisGetProductIds(BasketStore.getBasket().map((m) => m.product))
            BasketStore.setBasketProducts(product)
            console.log("getBasketProducts > " + JSON.stringify(product))

            ServisGetBasketProducts(BasketStore.getBasket().map((m) => m.product)).then((products) => {
                BasketStore.setBasketProducts(products)
                BasketStore.getBasket().map((b) => {

                    const product = BasketStore.getBasketProducts().find(p => p.productId === b.product)
                    console.log("product > " + JSON.stringify(product))

                    if (product && product.product_unit && product.product_unit[0] && product.product_unit[0].productPrices) {

                        aratoplam += product.product_unit[0].productPrices;
                        console.log("product > " + JSON.stringify(aratoplam))

                    }
                })
                aratoplam = aratoplam //- (aratoplam / 100) * 20
                kdv = (aratoplam / 100) * 20
                geneltoplam = teslimatucreti + aratoplam + kdv
            })

        }

        start()

    }, [LanguageStore.language, MainStore.getPageRefresh()]);
    try {
        if (!BasketStore.getBasket() || BasketStore.getBasket().length === 0) {

            console.log(BasketStore.getBasket())


        } else {
            BasketStore.getBasket().map((b) => {
                if (!BasketStore.getBasket()) {
                    //console.log(BasketStore.getBasket())
                } else {
                    //console.log(BasketStore.getBasket())
                    const product = BasketStore.getBasketProducts().find(p => p.productId === b.product)
                    if (product && product.product_unit && product.product_unit[0] && product.product_unit[0].productPrices) {
                        aratoplam += product.product_unit[0].productPrices;
                        //console.log("product > " + JSON.stringify(aratoplam))
                    }
                }
            })

            aratoplam = aratoplam //- (aratoplam / 100) * 20
            kdv = (aratoplam / 100) * 20
            geneltoplam = teslimatucreti + aratoplam + kdv
        }

    } catch (error) {
        //setBasketStatus(true)
    }
    function OrderSubmit(): void {
        throw new Error("Function not implemented.");
    }

    return (
        <><SearchBar /><NavbarButtons />
            <div className="container">
                <div className="row">
                    <PagesHeaderLinks text={i18n.t("Sepetim")} />
                </div>
            </div>

            {
                BasketStore.getBasket()?.length > 0 ?
                    <>
                        <div className="container">
                            <div className="row">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-6 mt-5">{BasketStore.getBasketProducts().length} {i18n.t("urun")}</div>
                                        <div style={{ color: "red" }} className="col-6 d-flex justify-content-end mt-5">{i18n.t("sepetiduzenle")}</div>
                                    </div>
                                    {BasketStore.getBasketProducts()?.map(item => {
                                        return (
                                            <>
                                                <ProductCardHorizantal product={item} />
                                            </>
                                        )
                                    })}


                                </div>
                                <div className="col-4">
                                    <div className="row mt-5">
                                        <div className="col-12"><br /></div>
                                    </div>
                                    <p></p>
                                    <div className="row ">
                                    </div>
                                    <div className="row">
                                        <div className="col-12 ms-2 me-5 border rounded">
                                            <OrderSummery yazi1={i18n.t("sepetozeti")} style1={{ fontWeight: "bold" }} yazi2={BasketStore.getBasketProducts()?.length + " " + i18n.t("urun")} style2={{ color: "gray" }} />
                                            <OrderSummery yazi1={i18n.t("toplamtutar")} yazi2={geneltoplam.toFixed(2) + " " + getCurrencyIcon()} />
                                            <OrderSummery yazi1={i18n.t("TeslimatUcreti")} yazi2={teslimatucreti.toString() + " " + getCurrencyIcon()} />
                                            <OrderSummery yazi1={i18n.t("ToplamIndirim")} yazi2={toplamindirim + " " + getCurrencyIcon()} />
                                            <OrderSummery yazi1="KDV" yazi2={kdv.toFixed(2) + " " + getCurrencyIcon()} />
                                            <OrderSummery yazi1={i18n.t("odenecektutar")} style1={{ fontWeight: "bold" }} yazi2={geneltoplam.toFixed(2) + getCurrencyIcon()} style2={{ color: "#0CC550" }} />


                                        </div>
                                        <div className="col-12 col-md-12 d-flex justify-content-center mb-2">
                                            <button onClick={e => { navigate("/order") }} type="submit" className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                                {i18n.t("devamet")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </> :
                    <>
                        <div className="container">
                            <div className="row mt-5 border border-1 rounded d-flex justify-content-center ">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center mb-3 mt-3" style={{ color: "gray" }}>{i18n.t("sepetinizdeurunbulunmuyor")}</div>
                                </div>
                                <div className="row">
                                    <div className="col-12  d-flex justify-content-center "><img src={convertSVGToDataURI(sepetresim)} alt="" /></div>
                                </div>
                                <div className="row">
                                    <div className="col-2 col-md-4 "></div>

                                    <div className="col-8 col-md-4  d-flex justify-content-center "><button onClick={e => { navigate("/") }} type="submit" className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                        {i18n.t("alisverisebasla")}
                                    </button>
                                    </div>
                                    <div className="col-2 col-md-4 "></div>
                                </div>
                            </div>
                        </div>
                    </>

            }
        </>
    )
}

const ObserverBasketPage = observer(BasketPage);
export default ObserverBasketPage
