import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import LanguageStore from '../../Stores/LanguageStore';
import { Card } from 'react-bootstrap';
import banner from '../../assets/sliderproductbannder.png';
import i18n from '../../config/languageConf';
import { HeaderText } from '../MenuHeaderText';
import ObserverDiscoverCard from '../DiscoverCard';


interface Item {
    id: number;
    title: string;
}

const items: Item[] = [
    { id: 1, title: 'Item 1' },
    { id: 2, title: 'Item 2' },
    { id: 3, title: 'Item 3' },
    { id: 4, title: 'Item 4' },
    { id: 5, title: 'Item 5' },

];

function DiscoverProduct() {
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        console.log("SliderProduct useEffect")
        //MainStore.setPageRefresh(!MainStore.getPageRefresh());

    }, [LanguageStore.language]);

    const scrollLeft = () => {
        if (listRef.current) {
            listRef.current.scrollBy({ left: -200, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (listRef.current) {
            listRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
    };
    return (
        <>
            <div className="container">
                <div className="row mt-5">
                <HeaderText headerLeftText={"BiSiparişi " + i18n.t("kesfet")} headerRightText={i18n.t("TumunuGor")} headrightDisplayed={false} />
                </div>
                <div className="row mt-4">
                    <ObserverDiscoverCard />
                </div>
            </div>
        </>
    );
}




const ObserverDiscoverBanner = observer(DiscoverProduct);
export default ObserverDiscoverBanner;
