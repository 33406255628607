import { observer } from "mobx-react";
import { IProduct } from "../Models/IProduct"
import FavoriteIcon from "./FavoriteIcon";
import { getCurrencyIcon } from "./getCurrencyIcon";
import { convertSVGToDataURI } from "./svgtoImage";
import { addbtnPruduct, negative, plus } from "../config/svgs";
import { getBasketQuantity } from "./getBasketQuantity";
import i18n from "../config/languageConf";
import decrementBtn from "./decrementBtn";
import incrementBtn from "./incrementBtn";

type ProductCardType = {
    product: IProduct;
}

function ProductCardHorizantal(product: ProductCardType){
    return(
        <div className="row border border-1 mt-3">
        <div className="col-2 d-flex justify-content-start">
            <div className="col-12 d-flex justify-content-center align-items-center">
                <img width={50} height={50} src={product.product.img_path[0]?.relativePath + product.product.img_path[0]?.imgPath} alt="" />
            </div>
        </div>
        <div className="col-4 d-flex flex-column justify-content-between">
            <div className="row">
                <div className="col-12 mt-2">
                    {product.product.product_name}
                </div>
            </div>
            <div className="col-12 mb-2 mt-5 flex-column d-flex justify-content-end">
               {i18n.t("urunnotu")}
            </div>
        </div>
        <div className="col-6 d-flex justify-content-end">
            <div className="col-12 d-flex flex-column justify-content-between">
                <div className="row">
                    <div style={{ color: "red" }} className="col-12 mt-2 d-flex justify-content-end">
                        {product.product.price[0].price} {getCurrencyIcon()}
                    </div>
                </div>
                <div className="col-12 mb-2 d-flex justify-content-end">
                    <div className="col-12 flex-column d-flex justify-content-end basketaddedbutton">
                        <div className="quantity-selector">
                            <div onClick={e => {decrementBtn(product.product, e)}} className="col-2"><img src={convertSVGToDataURI(negative)} id="decrement" /></div>
                            <div className="col-8"><b id="quantity">{getBasketQuantity(product.product)} {product.product.product_unit[0].description}</b></div>
                            <div onClick={e => {incrementBtn(product.product, e)}} className="col-2"><img src={convertSVGToDataURI(plus)} id="increment" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

const ObserverProductCardHorizantal = observer(ProductCardHorizantal);
export default ObserverProductCardHorizantal; 