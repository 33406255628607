import { I18n } from "i18n-js";
import LanguageStore from "../Stores/LanguageStore";
import { tr } from "../lang/tr";
import { en } from "../lang/en";
import { de } from "../lang/de";
import { useEffect } from "react";

const i18n = new I18n({
  ...tr,
  ...en,
  ...de,
});


console.log("language conf > ");

i18n.enableFallback = true;
i18n.translations = { en, de, tr };
i18n.locale = LanguageStore.language;

console.log("language conf > " + LanguageStore.language + " locale " + i18n.locale);

export default i18n;