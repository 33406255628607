import React, { useEffect } from 'react';
import MainStore from '../Stores/MainStore';
import LanguageStore from '../Stores/LanguageStore';
import { observer } from 'mobx-react';
import Slider from 'react-slick';
import silider from '../assets/silider1.png';

const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '20px', marginTop: '-10%', zIndex: 1}}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '20px', marginTop: '-10%', zIndex: 1 }}
      onClick={onClick}
    />
  );
};

function NavbarBanner() {
  useEffect(() => {
    console.log("NavigationBar useEffect")
    //MainStore.setPageRefresh(!MainStore.getPageRefresh());

  }, [LanguageStore.language, MainStore.getPageRefresh()]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dotsClass: 'slider-dots',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row col-12">
          <div className="slider-container">
            <Slider {...settings}>
              <div>
                <img src={silider} width="100%" alt="" />
              </div>
              <div>
                <img src={silider} width="100%" alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>


    </>
  );
}


const ObserverNavbarBannerScreen = observer(NavbarBanner);
export default ObserverNavbarBannerScreen;
