import { Config_GetInsert, Config_GetInsertProducts, DEFAULT_LINK } from "./servisconfig";
import { IInsert } from "../Models/IInsert";
import { IInsertProduct } from "../Models/IInsertProduct";

export async function ServisGetInsert(): Promise<IInsert[]> {
    var data = fetch(DEFAULT_LINK + Config_GetInsert, {
        method: "GET",
        headers: {'Content-Type': 'text/plain' },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetInsert Service Error: " + error);
    });
    return data;
}

export async function ServisGetInsertProduct(InsertId:number): Promise<IInsertProduct[]> {
    var data = fetch(DEFAULT_LINK + Config_GetInsertProducts + "?insertId=" + InsertId, {
        method: "GET",
        headers: {'Content-Type': 'text/plain' },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetInsertProduct Service Error: " + error);
    });
    return data;
}

