import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../src/css/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Home from './pages/Home/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import LanguageStore from './Stores/LanguageStore';
import MainStore from './Stores/MainStore';
import SearchBar from './components/SearchBar';
import NavbarButtons from './components/navbarButtons';
import Footer from './components/footer';
import Login from './pages/Login/LoginPage';
import RegisterPage from './pages/Login/RegisterPage';
import StoreAdressPage from './pages/StoreAdress/StoreAdressPage';
import { observer } from 'mobx-react';
import CampainsPage from './pages/Campains/CampainsPage';
import BasketPage from './pages/Basket/BasketPage';
import OrderSuccess from './pages/Orders/OrderSuccess';
import OrderPages from './pages/Orders/OrderPages';
import MyAdressPages from './pages/Account/AccountMyAdress';
import MyOrdersPages from './pages/Account/AccountMyOrders';
import SearchPage from './pages/Search/SearchPage';
import AccountMySettingsPages from './pages/Account/AccountMySettings';
import ContactPage from './pages/Contact/ContactPage';
import CategoriesPage from './pages/Categories/CategoriesPage';
import ProductDetails from './pages/Product/ProductDetails';
import { ServisGetCity, ServisGetCountry, ServisGetTown } from './services/GeoServis';
import GeoStore from './Stores/GeoStore';
import FavoritesPage from './pages/Favorites/FavoritesPage';
import { ToastContainer } from 'react-toastify';
import { Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {

  useEffect(() => {
    console.log("App useEffect")

  }, []);
  return (
    <Router>
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/stores" element={<StoreAdressPage />} />
        <Route path="/campains" element={<CampainsPage />} />
        <Route path="/basket" element={<BasketPage />} />
        <Route path="/ordersuccess" element={<OrderSuccess />} />
        <Route path="/order" element={<OrderPages />} />
        <Route path="/account/myadress" element={<MyAdressPages />} />
        <Route path="/account/myorders" element={<MyOrdersPages />} />
        <Route path="/account/mysetting" element={<AccountMySettingsPages />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/product/detail" element={<ProductDetails />} />
        <Route path="/favorites" element={<FavoritesPage />} />

        <Route path="/search" element={<SearchPage />} />

      </Routes>
      <Footer />
    </Router>

  );
}

const ObserverMemberAdressScreen = observer(App);
export default ObserverMemberAdressScreen;