const de = {
  welcome: "Hallo zusammen",
  AdresimeGelsinAdres: "Hereinspaziert Adresse, Anschrift, Informationen",
  EnyakınTeslimat: "Nächstgelegene Lieferung",
  Cumartesi: "Samstag",
  Anasayfa: "Heimat",
  Kategoriler: "Kategorien",
  Sepetim: "Korb",
  Kampanyalar: "Kampagnen",
  Hesabım: "Mein Konto",
  Siparişlerim: "Meine Befehle",
  Adreslerim: "Meine Adressen",
  Favorilerim: "Meine Favoriten",
  Mağazalar: "Geschäfte",
  Kurumsal: "Unternehmen",
  Ayarlar: "Einstellungen",
  hesabim_1: "Mein Konto",
  hesabim_2: "Meine Bestellungen",
  hesabim_3: "Meine Adressen",
  hesabim_4: "Geschenkgutscheine",
  hesabim_5: "Meine Favoriten",
  hesabim_6: "Meine Einkaufsliste",
  hesabim_7: "Bestandsmitteilung",
  hesabim_8: "Filialen",
  hesabim_9: "Unternehmen",
  hesabim_10: "Einstellungen",
  hesabim_11: "Abmelden",
  OdemeSecimi: "Zahlungsauswahl",
  SiparisOzeti: "Zusammenfassung der Bestellung",
  AraToplam: "Zwischensumme",
  Uygula: "Anwenden",
  indirimKodu: 'Rabatt-Code verwenden',
  hediyeCeki: "Geschenkgutschein einlösen",
  KDV: "Steuer",
  ToplamIndirim: "TGesamtrabatt",
  TeslimatUcreti: "Liefergebühr",
  GenelToplam: "Gesamtsumme",
  Devam: "Weiter",
  BirlikteAlinan: "Gemeinsam erhaltene Produkte",
  Teslimat: "Lieferung",
  OdemeB: "BEZAHLUNG",
  TeslimatB: "LIEFERUNG",
  AdresB: "ADRESSE",
  SanaUygunGun: "Wählen Sie den Tag und das Zeitintervall, das Ihnen passt",
  day_1: "Montag",
  day_2: "Dienstag",
  day_3: "Mittwoch",
  day_4: "Donnerstag",
  day_5: "Freitag",
  day_6: "Samstag",
  day_7: "Sonntag",
  SonrakiAdim: "Nächster Schritt",
  Adres: "Adresse",
  YeniAdresEkle: "Neue Adresse hinzufügen",
  FaturaIcinAyniAdres: "Verwenden Sie dieselbe Adresse für die Rechnung",
  FaturaAdresiniSecin: "Rechnungsadresse auswählen",
  YeniFaturaAdresi: "New Invoice Address",
  TumunuGor: "Alle sehen",
  adresSec: "Lieferadresse auswählen",
  TeslimatAdresin: "Ihre Lieferadresse",
  ziliCalma: "Läuten Sie die Glocke",
  TemassizTeslimat: "Kontaktlose Zustellung",
  TeslimatZamani: "Ihre Lieferfrist",
  RandevuluTeslimat: "Terminzustellung",
  odemeYontemiSec: "Zahlungsmethode festlegen",
  paymentTypeName_1: "Lieferung per Nachnahme",
  paymentTypeName_2: "Kartenzahlung an der Tür",
  paymentTypeName_3: "Online-Zahlung",
  paymentTypeName_4: "Überweisung / EFT",
  siparisOzeti: "Zusammenfassung der Bestellung",
  siparisNotunuz: "Ihre Bestellnotiz",
  OnBilgilendirme: "Vorläufige Informationsbedingungen'",
  okudumOnayliyorum: "Ich habe gelesen und stimme zu",
  mesafeliSatis: "Ihr Fernabsatzvertrag",
  onaylıyorum: "Ich habe gelesen und stimme zu",
  odemeyiTamamla: "Vollständige Zahlung",
  siparisDone: "Ihre Bestellung wurde erfolgreich abgeschlossen.",
  siparisDetayiIletim: "Ihre Bestelldetails wurden an Ihre E-Mail-Adresse gesendet.",
  alisVerisDevam: "CONTINUE SHOPPING",
  odemeAlinmistir: 'PAYMENT RECEIVED',
  urunArama: 'Produktsuche',
  urunAra: 'Produkt suchen...',
  filtrele: "Filter",
  gorunum: "Ansicht",
  sirala: "Sortieren",
  oneCikanAramalar: "Herausragende Suchanfragen",
  iletisimsetting: "Sie können Ihre Kommunikationspräferenzen jederzeit oben ändern. Wir empfehlen Ihnen, eine der Optionen zu aktivieren, um die Möglichkeiten zu nutzen und die Kampagnen nicht zu verpassen.",
  iletisimeposta: "Kontakt per E-Mail",
  iletisimsms: "Kontaktaufnahme per SMS",
  settings: 'Einstellungen',
  diltercihi: 'Sprachpräferenz auswählen',
  onayMesaj: "Bestätigungsnachricht",
  anasayfaYonlendirme: "Sie werden zur Homepage für Sprachwechsel.... weitergeleitet",
  OK: "OK",
  iletisim: "Kontakt",
  Iadeislemlerivesorularınızicin: "Für Rücksendungen und Fragen",
  CagrıMerkezimiz: "Unser Call Center",
  veya: "oder",
  WhatsappHattimiz: "Whatsapp Leitung",
  ileiletisimegecebilirsiniz: "Sie können Kontakt aufnehmen mit",
  MusteriHizmetleri: "Kundendienst",
  Hergun: "Jeden Tag",
  Ad: 'Name',
  Soyad: 'Nachname',
  EPosta: 'E-Mail',
  CepTelefonu: 'Mobiltelefon',
  DogumTarihi: "Geburtsdatum",
  Hesabim: "Mein Konto",
  EPostailetisimineizinveriyorum: "Ich erlaube E-Mail-Kommunikation",
  SMSgonderimineizinveriyorum: "Ich erlaube den Versand von SMS",
  Kaydet: "Speichern",
  ParolamiDegistir: "Mein Passwort ändern",
  ParolaYenile: "Passwort erneuern",
  EskiSifreniz: "Ihr altes Passwort",
  Goster: "Anzeigen",
  YeniSifreniz: "Ihr neues Kennwort",
  YeniSifrenizTekrar: 'Ihr neues Passwort erneut',
  Ay: 'Mond',
  Gun: 'Tag',
  Yil: "Jahr",
  Siparislerim: "Meine Bestellungen",
  BaslangicTarihi: "Anfangsdatum:",
  BitisTarihi: "Enddatum:",
  FaturaTarihi: "Rechnungsdatum",
  FaturaNo: "Rechnungsnummer",
  FaturaT: "Rechnung",
  Ara: "Suche",
  HediyeCeklerim: "GeschenkSchecks",
  Favorilereeklediginiz: "In diesem Bereich können Sie die Produkte, die Sie zu Ihren Favoriten hinzugefügt haben, prüfen und kaufen.",
  StokBildirimi: "Bestandsmitteilung",
  SectiginizUrunler: "Sie werden benachrichtigt, wenn die von Ihnen ausgewählten Produkte wieder auf Lager sind.",
  Magazalar: "Läden",
  TumMagazalar: "Alle Läden",
  YakinimdakiMagazalar: "Geschäfte in meiner Nähe",
  Aramayapin: "Suche unter",
  Indirimler: "Ermäßigungen",
  Sec: "Auswählen",
  Markalar: "Marken",
  UrunleriGoruntule: "Produkte anzeigen",
  Filtrele: "Filter",
  Gorunum: "Erscheinungsbild",
  KartSeklinde: "Kartenförmig",
  ListeSeklinde: "Listenförmig",
  IndirimMiktarinaGore: "İEntsprechend dem Rabattbetrag",
  OnceYuksekFiyat: "Hoher Preis zuerst",
  OnceDusukFiyat: "Niedriger Preis zuerst",
  IndirimYuzdesineGore: "Nach Rabattprozentsatz",
  SatinAldiklarimaGore: "Nach meinen Einkäufen",
  CokSatanlar: "Verkaufsschlager",
  Onerilenler: "Empfohlen",
  Sirala: "Sortieren",
  INDIRIM: "RABATT",
  Adet: "Menge",
  Dashboard: "Armaturenbrett",
  TOTALSALES: "GESAMTUMSATZ",
  SvgListesi: "Svg Verzeichnis",
  ProductListesi: "Produktliste",
  SiparisListesi: "Auftragsliste",
  KullaniciListesi: "Benutzerliste",
  TeslimatAyarlari: "Liefereinstellungen",
  OdemeAyarlari: "Zahlungseinstellungen",
  Totalsalesorder: "Gesamtverkaufsauftrag",
  Avgsalesperorder: "Durchschnittlicher Umsatz pro Auftrag",
  comparetolastweek: "im Vergleich zur letzten Woche",
  CategoryName: "Name der Produkt",
  ProductName: "Name der Kategorie",
  Urunler: "Ürünler",
  ProductCode: "Ürünler Kodu",
  Aciklama: "Açıklama",
  urunEkleGuncelle: "Ürün Ekle / Güncelle",
  durum: "Durum",
  evet: "Evet",
  hayir: "Hayir",
  satisDurumu: "Satış Durmu",
  MainGroup: "Main Group",
  resimler: "Abbildungen",
  fiyatlar: "fiyatlar",
  units: "Verpacken",
  UnitDescription: "Beschreibung der Einheit",
  Factor: "Factor",
  Weiqht: "Weiqht",
  Width: "Width",
  Lenqth: "Lenqth",
  Heiqht: "Heiqht",
  Tare: "Tare",
  Add: "Add",
  type: "Type",
  price: "Price",
  priceType: "Price Type",
  productUnit: "Product Unit",
  PriceAdd: "Price Add",
  Guncelle: "Güncelle",
  close: "Close",
  category: "category",
  unitCode: "Unit Code",
  UnitAdd: "Unit Add",
  siparisId: 'Sipariş Id',
  sipariszamani: 'Sipariş Zamanı',
  siparisveren: 'Sipariş Veren',
  siparisadresi: 'Sipariş Adresi',
  siparisdurumu: 'Sipariş Durumu',
  siparistutar: 'Tutar',
  detay: "Detay",
  yazdir: "Yazdır",
  kullaniciId: 'Kullanıcı Id',
  kullaniciAdi: 'Kullanıcı Adı',
  KullaniciSoyad: 'Kullanıcı Soyad',
  KullaniciMail: 'Kullanıcı Mail',
  FiyatTipi: 'Fiyat Tipi',
  Kullanicilar: "Benutzer",
  name: "Ad",
  surname: "Soyad",
  email: "Email",
  phone: "Phone",
  password: "Password",
  KullanciEkleGuncelle: "Kullancı Ekle / Güncelle",
  ErpCode: "erpCode",
  MemberEdit: "Member Edit",
  isActive: "Active",
  duplicate_email: "Duplicate Email",
  newMember: "Neuer Benutzer",
  LastOrderedUsers: "Zuletzt bestellte Benutzer",
  Kategori: "Category",
  urunAdi: "Ürün Adı",
  OrderEdit: "Bestellung bearbeiten",
  sil: "löschen",
  edit: "Eearbeiten",
  kategoriGuncellendi: "Kategori Güncellendi",
  KampanyaAdi: "Kampanya Adı",
  indirimtip: "İndirim Tipi",
  baslangicTarihi: "Başlangıç Tarihi",
  kampanyalarGuncellendi: "Kampanyalar Güncellendi",
  kullaniciListesiGuncellendi: "Kullanici Listesi Güncellendi",
  siparistelefon: 'Sipariş Telefon',
  tamamlanan: "Abgeschlossen",
  iptalEdilen: "Abgebrochen",
  acik: "Offen",
  tumu: "Alle",
  print: "Print",
  DAILYPLATFORMRATE: "TÄGLICHE PLATTFORMRATE",
  comparetolastday: "Vergleich zum letzten Tag",
  WEEKLYANALYTICS: "WÖCHENTLICHE ANALYTIK",
  fromprevious: "der letzten 15 Tage",
  title: "Titel",
  taxNumber: "Steuernummer",
  taxOffice: "Finanzamt",
  isSelectedInvoice: "Aktif Fatura A.",
  isSelectedShipping: "Aktif Teslimat A.",
  code: "Code",
  guncelledi: "Güncellendi",
  Marka:"Marka",
  teslimatgunleri:"Tage der Lieferung",
  Resimler:"Bilder",
  Resimli:"Bebildert",
  Resimsiz:"Kein Bild",
  Temizle:"Temizle",
  AktifOlanlar :"Aktiv",
  AktifOlmayanlar:"Inaktiv",
  ProductCategories :"Produkt-Kategorien",
  YeniKategoriEklendi: "Neue Kategorie hinzugefügt",
  MarklarEkleGuncelle:"Brands / Updated",
  kategoriler1:"Obst & Gemüse",
  kategoriler2:"Fleisch & Huhn & Delikatessen",
  kategoriler3:"Milch und Molkereiprodukte",
  kategoriler4:"Frühstück",
  kategoriler5:"Brot & Konditorei",
  kategoriler6:"Gefrorene Produkte",
  kategoriler7:"Essbare Zutaten",
  kategoriler8:"Imbiss",
  kategoriler9:"Getränke",
  kategoriler10:"Körperpflege & Kosmetik",
  kategoriler11:"Mutter-Baby & Kind",
  kategoriler12:"Reinigung",
  kategoriler13:"Papierprodukte",
  kategoriler14:"Tierfreunde",
  kategoriler15:"Elektronik",
  kategoriler16:"Kleidung, Schuhe und Accessoires",
  kategoriler17:"Haus & Wohnen",
  yardim:"Hilfe",
  yardim1:"Rechtliche Hinweise",
  yardim2:"Sicherheitspolitik",
  yardim3:"Nutzungsbedingungen",
  yardim4:"Schutz von personenbezogenen Daten",
  yardim5:"Über uns",
  yardim6:"Unternehmen",
  yardim7:"Investor Relations",
  hesabim:"Mein Konto",
  hesabim1:"Mein Konto",
  hesabim2:"Meine Befehle",
  hesabim3:"Meine Benutzerinformationen",
  hesabim4:"Meine Adressen",
  hesabim5:"Meine bevorzugten Produkte",
  hesabim6:"Zahlungsarten",
  hesabim7:"Informationen zur Rechnungsstellung",
  biziarayin:"Rufen Sie uns an 0 850 000 00 00 00 Schreiben Sie uns: destek@bisiparis.de",
  footertext:"Mit unserer mobilen Anwendung sind wir immer für Sie da!",
  magazalarimiz:"Unsere Läden ",
  enyakinmagaza:"Wählen Sie eine Stadt/Landkreis aus, um den nächstgelegenen Bisipariş Store zu finden...",
  sehir:"Stadt",
  ilce:"Bezirk",
  kaydol:"Registrieren Sie sich",
  kesfet:"Entdecken Sie",
  girisyap:"Eintragen",
  stoktayok:"Nicht auf Lager",
  stoktavar:"Vorrätig",
  markaara:"Suche nach Marken",
  buguneozelkampanyalar:"Bisipariş Sonderkampagnen für heute",
  siparisolustu:"Ihre Bestellung wurde erfolgreich erstellt.",
  alisverisedevam:"Weiter einkaufen",
  alisverisebasla:"Einkaufen beginnen",
  degisikliklerikaydet:"Änderungen speichern",
  hesapayarlari:"Kontoeinstellungen",
  dil:"GE",
  urunnotu:"Produkthinweis hinzufügen",
  teslimatadreslerim:"Lieferadressen",
  teslimatadresiekle:"Lieferadresse hinzufügen",
  faturaadreslerim:"Meine Rechnungsadressen",
  son1ay:"Letzter 1 Monat",
  son1aysiparisbulunmuyor:"Es gibt keine Bestellungen, die Sie in den letzten 1 Monat aufgegeben haben. Sie können mit dem Einkaufen beginnen, indem Sie jetzt bestellen.",
  degistir:"Ändern Sie es",
  onayli:"ANGENOMMEN",
  onaysiz:"NICHT ZUGELASSEN",
  sepetinizdeurunbulunmuyor:"Es sind keine Artikel in Ihrem Warenkorb!",
  logintext:"Bitte geben Sie Ihre registrierte Telefonnummer/E-Mail-Adresse und Ihr Passwort ein",
  telefonmail:"Telefon / Postanschrift",
  sifre:"Passwort",
  hesabinyoksa:"Wenn Sie noch kein Konto haben",
  kayitol:"Jetzt anmelden!",
  registertext:"Bitte geben Sie Ihre Daten vollständig und korrekt an.",
  registerplaceholder1:"Name Nachname",
  registerplaceholder2:"Telefon Nr.",
  sifretekrar:"Passwort wieder",
  zatenkayitliysan:"Wenn Sie bereits registriert sind",
  zamanaralıgı:"Wählen Sie das Zeitintervall, in dem Ihre Bestellung versendet werden soll",
  teslimatadresi:"Lieferadresse",
  ucretsiz:"Unentgeltlich",
  odemebilgileri:"Bitte geben Sie Ihre Zahlungsdaten ein.",
  kartno:"Karte Nr.",
  kartuzerindekiisim:"Name Nachname",
  sonkullanmatarihi:"Verfallsdatum",
  guvenlikkodu:"Sicherheitscode",
  devamet:"Weiter",
  sepetozeti:"Korb Zusammenfassung",
  toplamtutar:"Gesamtbetrag",
  odenecektutar:"Zu zahlender Betrag",
  urun:"Produkt",
  sepetiduzenle:"Organisieren Korb",
  hataliparolatekrari:"Falsche Wiederholung des Passworts",
  iletisimformu:"Kontakt-Formular",
  konu:"Thema",
  mesaj:"Nachricht",
  mahalle:"Nachbarschaft",
  binano:"Gebäude Nr.",
  katno:"Boden Nr.",
  daire:"Wohnung",
  adrestarifi:"Adresse Wegbeschreibung*",
  caddesokak:"Straße/Straße*",
  adresbasligi:"Titel der Adresse (z.B.: Zuhause)",
  kisiselbilgiler:"Persönliche Informationen",
  adresbilgisi:"Adresse Details",
  taptaze:"Frisches Obst und Gemüse!",
  adressilme:"Adresse löschen",
  adressilmeonay:"Sind Sie sicher, dass Sie die Adresse löschen wollen?",
  iletisimtercihi:"Meine Kommunikationspräferenzen",
  bilgilendirmeamacli:"Kampagne und Informationen von Bisipariş.de",
  smsonay:"Ich möchte SMS empfangen.",
  cagrionay:"Ich möchte einen Telefonanruf erhalten",
  epostaonay:"Ich möchte eine E-Mail erhalten",
  musterihizmetleriiletisim:"Kontakt zum Kundenservice",
  sepeteekle:"In den Warenkorb legen",
  detailpagetext:"Die Bestands-, Preis- und Kampagneninformationen des Produkts werden entsprechend den Bestands-, Preis- und Kampagneninformationen der Filiale, die die Lieferung vornimmt, ermittelt.",
  urunaciklama:"Beschreibung des Produkts",
  urunbilgisi:"Informationen zum Produkt",
  iadekosul:"Rückgabebedingungen",
  urunaciklama1:"*100% Natürlich",
  urunaciklama2:"*Nicht-GVO",
  toplammagaza:"258 aufgelistete Läden insgesamt",
  KullaniciBilgileriDoğrulanamadı:"Überprüfung der Benutzerdaten fehlgeschlagen",

};
export { de };