import { makeAutoObservable } from "mobx";
import { IProduct, IProductFilter } from "../Models/IProduct";

class ProductStore {
  Product:IProduct[] = [];
  SelectedProduct:IProduct | null = null;
  ProductFilter:IProductFilter | null = null;


  constructor() {
    makeAutoObservable(this);
  }

  getSelectedProduct() {
    return this.SelectedProduct;
  }

  setSelectedProduct(SelectedProduct: IProduct){
    this.SelectedProduct = SelectedProduct;
  }

  getProduct() {
    return this.Product;
  }

  setProduct(Product: IProduct[]){
    this.Product = Product;
  }

  getProductFilter() {
    return this.ProductFilter
  }

  setProductFilter(ProductFilter: IProductFilter) {
    this.ProductFilter = ProductFilter;
  }
}

export default new ProductStore();