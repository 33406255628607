import { observable, action, makeAutoObservable } from "mobx";
import { ICategory, IProductCategory } from "../Models/ICategory";

class CategoriesStore {
  @observable Categories:ICategory[] | null = [];
  @observable SelectedCategories:ICategory | null = null;
  @observable ProductCategories:IProductCategory[] | null = [];
  @observable SelectedProductCategories:IProductCategory | null = null;



  constructor() {
    makeAutoObservable(this)
  }

  @action getSelectedCategories() {
    return this.SelectedCategories
  }

  @action setSelectedCategories(SelectedCategories:ICategory){
    this.SelectedCategories = SelectedCategories
  }

  @action getCategories() {
    return this.Categories;
  }

  @action setCategories(Categories: ICategory[]){
    this.Categories = Categories;
  }

  @action getProductCategories() {
    return this.ProductCategories
  }

  @action setProductCategories(ProductCategories:IProductCategory[]){
    this.ProductCategories = ProductCategories;
  }

  @action getSelectedProductCategories() {
    return this.SelectedProductCategories
  }
  @action setSelectedProductCategories(SelectedProductCategories:IProductCategory) {
    this.SelectedProductCategories = SelectedProductCategories
  }


}

export default new CategoriesStore();