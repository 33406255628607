// src/Login.js
import React, { useEffect, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import DiscoverCard from '../../components/DiscoverCard';
import PagesHeaderLinks from '../../components/PagesHeaderLinks';

const CategoriesPages = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("NavigationBar useEffect")


    }, [LanguageStore.language, MainStore.PageRefresh]);

    return (
        <><SearchBar /><NavbarButtons />
            <div className="container">
                <PagesHeaderLinks text={i18n.t("Kategoriler")} />
                <hr />
                <div className="row mt-4">
                    <DiscoverCard />
                </div>


            </div>
        </>
    );
};
const ObserverCategoriesPages = observer(CategoriesPages);
export default ObserverCategoriesPages;
