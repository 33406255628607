import FavoriteStore from "../Stores/FavoriteStore";
import { ServisAddFavorites, ServisDeleteFavorites } from "../services/FavoriteServis";
import { IFavorite } from "../Models/IFavorite";
import BasketStore from "../Stores/BasketStore";
import ProductStore from "../Stores/ProductStore";
import { IProduct } from "../Models/IProduct";
import { ServisAddBasket, ServisGetBasket } from "../services/BasketServis";
import { IBasket } from "../Models/IBasket";
import MainStore from "../Stores/MainStore";



export default async function incrementBtn(product: IProduct, props: any) {
    console.log("incrementBtn" + product)

    console.log('Item Pressed:', product.product_unit[0].id);
    const token = await localStorage.getItem('token');
    //console.log("token >" + token)
    console.log("if öncesi basket product_unit>" + product.product_unit[0].id)

    if (token == null) {
        console.log("token bulunamadı")
        window.location.href = '/login';
        return
        const new_basket: IBasket[] = [{
            id: product.productId + BasketStore.getBasket()?.length,
            member: 0,
            token: 0,
            product: product.productId,
            unit: product.product_unit[0].id,
            quantity: 1,
            createdTime: new Date(),
            updatedTime: new Date(),
            isdeleted: false,
            isdeletedReason: new Date(),
            isdeletedTime: new Date()
        }]

        console.log("new basket " + new_basket);
        console.log("basket sayisi>" + BasketStore.getBasket())
        if (BasketStore.getBasket()) {
            BasketStore.setBasket([...BasketStore.getBasket(), new_basket[0]])

        } else {
            BasketStore.setBasket(new_basket)
        }

    }
    else {
        console.log("basket product_unit>" + product.product_unit[0].id)
        const new_basket: IBasket = {
            id: 1,
            member: 1,
            token: 1,
            product: product.productId,
            unit: product.product_unit[0].id,
            quantity: 1,
            createdTime: new Date(),
            updatedTime: new Date(),
            isdeleted: false,
            isdeletedReason: new Date(),
            isdeletedTime: new Date()
        }
        ServisAddBasket(new_basket).then((t) => {
            console.log("ServisAddBasket gelen>" + t)
            ServisGetBasket().then((t) => {
                BasketStore.setBasket(t)
                MainStore.setPageRefresh(!MainStore.getPageRefresh())
            });
        })
        console.log("basket sayisi>" + BasketStore.getBasket())

    }


}