import { observer } from "mobx-react";
import { useEffect } from "react";
import LanguageStore from "../Stores/LanguageStore";
import MainStore from "../Stores/MainStore";
import { ServisGetCategories, ServisGetProductCategories } from "../services/CategoryServis";
import CategoriesStore from "../Stores/CategoriesStore";
import { convertSVGToDataURI } from "./svgtoImage";
import { ICategory } from "../Models/ICategory";
import ProductStore from "../Stores/ProductStore";
import { useNavigate } from "react-router";

type props = {
    prop?: any


}

function DiscoverCard(props: props) {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("SearchBar useEffect")
        async function start() {
            const categorys = await ServisGetCategories()
            CategoriesStore.setCategories(categorys);
            console.log("categories: " + categorys.length)
        }
        start();
    }, [LanguageStore.language, MainStore.getPageRefresh()]);

    function pressedItem(item: ICategory) {
        console.log("selected   category > " + item.categoryId)
        MainStore.setIsLoading(true);
        ServisGetProductCategories(item.categoryId).then(products => {
            ProductStore.setProduct(products)
            navigate("/search")
            MainStore.setIsLoading(false);

        })
    }

    return (
        <>
            {
                CategoriesStore.getCategories()?.map(category => {
                    return (
                        <div key={category.categoryId} className='col-6 col-md-3' onClick={e => pressedItem(category)}>
                            <div style={{ height: 200, cursor: "pointer" }} className="border border-1 rounded">
                                <div className="row">
                                    <div className="col d-flex  justify-content-center   align-items-center gap-0">
                                        <img width={110} style={{ margin: "50px" }} src={convertSVGToDataURI(category.svgValue)} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="d-flex  justify-content-center align-items-center ms-2 mt-2 mb-2 mt-2 mb-2 ">{category.categoryName?.toUpperCase()}</div>
                            </div>
                        </div>
                    )
                })
            }

        </>
    )
}

const ObserverDiscoverCard = observer(DiscoverCard);
export default ObserverDiscoverCard;

