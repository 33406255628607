import { makeAutoObservable } from "mobx";
import { IInsert } from "../Models/IInsert";
import { IInsertProduct } from "../Models/IInsertProduct";
import { IProduct } from "../Models/IProduct";

class InsertStore {
  Insert:IInsert[] = [];
  Insertproduct:IInsertProduct[] = [];
  InsertProductProducs:IProduct[] = [];


  constructor() {
    makeAutoObservable(this);
  }

  getInsert() {
    return this.Insert;
  }

  setınsert(Insert: IInsert[]){
    this.Insert = Insert;
  }

  getInsertProduct() {
    return this.Insertproduct;
  }

  setInsertProduct(Insertproduct: IInsertProduct[]){
    this.Insertproduct = Insertproduct;
  }

  getInsertProductProducs() {
    return this.InsertProductProducs;
  }

  setInsertProductProducs(InsertProductProducs: IProduct[]){
    this.InsertProductProducs = InsertProductProducs;
  }
}

export default new InsertStore();