import { IDeliveryTime } from "../Models/IDeliveryTime";
import { IDeliveryType } from "../Models/IDeliveryType";
import { IGlobalSetting } from "../Models/IGlobalSetting";
import { ISearchHit } from "../Models/ISearchHit";
import { IWeekDay } from "../Models/IWeekDay";
import { Config_DeleteDays, Config_GetDeliveryTimes, Config_GetDeliveryTypes, Config_GetGlobalSettings, Config_GetPaymentTypes, Config_GetSearchHits, Config_GetWeekDays, DEFAULT_LINK } from "./servisconfig";


export function ServisGetWeekDays(): Promise<IWeekDay[]> {
    var data = fetch(DEFAULT_LINK + Config_GetWeekDays, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetWeekDays Service Error: " + error);
    });
    return data;
}

export function ServisDeleteWeekDays(day:IWeekDay): Promise<IWeekDay[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_DeleteDays, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(day)
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteWeekDays Service Error: " + error);
    });
    return data;
}

export function ServisGetDeliveryTimes(dayId:number): Promise<IDeliveryTime[]> {
    var data = fetch(DEFAULT_LINK + Config_GetDeliveryTimes + "?dayId="+dayId, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetDeliveryTimes Service Error: " + error);
    });
    return data;
}

export function ServisGetDeliveryTypes(): Promise<IDeliveryType[]> {
    var data = fetch(DEFAULT_LINK + Config_GetDeliveryTypes, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetDeliveryTypes Service Error: " + error);
    });
    return data;
}



export function ServisGetSearchHits(): Promise<ISearchHit[]> {
    var data = fetch(DEFAULT_LINK + Config_GetSearchHits, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetSearchHits Service Error: " + error);
    });
    return data;
}

export function ServisGetGlobalSettings(): Promise<IGlobalSetting[]> {
    var data = fetch(DEFAULT_LINK + Config_GetGlobalSettings, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetGlobalSettings Service Error: " + error);
    });
    return data;
}