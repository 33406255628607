import { IProduct } from "../Models/IProduct";

export default function getImageUrl(product: IProduct): string {
    //console.log("getImageUrl"+ product.img_path)
    let baseUrl = ""
    if (product && product.img_path && product.img_path[0] && product.img_path[0].relativePath !== null && product.img_path[0].relativePath !== undefined) {
        baseUrl = product?.img_path[0].relativePath + product?.img_path[0].imgPath
        return baseUrl;
    }
    else {
        baseUrl = "../favicon.ico"
    }
    //console.log(baseUrl)
    return baseUrl
}