import { ICategory, IProductCategory } from "../Models/ICategory";
import { IProduct } from "../Models/IProduct";
import { Config_AddCategory, Config_AddProductCategories, Config_DeleteCategory, Config_DeleteProductCategories, Config_GetCategory, Config_GetProductCategories, Config_GetSubCategories, Config_UpdateCategory, Config_UpdateProductCategories, DEFAULT_LINK } from "./servisconfig";

export function ServisGetProductCategories(categoryId:number): Promise<IProduct[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetProductCategories + "?categoryId=" +categoryId  , {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(category)
    }).then((response) => response.json()).then((json) => {
        //console.log("ServisGetProductCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetProductCategories Service Error: " + error);
    });
    return data;
}

export function ServisGetSubCategories(): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_GetSubCategories  , {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        //body: JSON.stringify(category)
    }).then((response) => response.json()).then((json) => {
        //console.log("ServisGetProductCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetSubCategories Service Error: " + error);
    });
    return data;
}

export function ServisAddProductCategories(productCategory:IProductCategory): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddProductCategories, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(productCategory)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisAddProductCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddProductCategories Service Error: " + error);
    });
    return data;
}

export function ServisUpdateProductCategories(productCategory:IProductCategory): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_UpdateProductCategories, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(productCategory)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisAddProductCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddProductCategories Service Error: " + error);
    });
    return data;
}

export function ServisGetCategories(): Promise<ICategory[]> {
    var data = fetch(DEFAULT_LINK + Config_GetCategory, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetCategories Service Error: " + error);
    });
    return data;
}

export function ServisAddCategories(category: ICategory): Promise<ICategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_AddCategory, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(category)
    }).then((response) => response.json()).then((json) => {
        console.log("ServisAddCategories Gelen > " + JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisAddCategories Service Error: " + error);
    });
    return data;
}

export function ServisUpdateCategories(category: ICategory): Promise<ICategory[]> {
    //console.log("ServisUpdateCategories giden"+JSON.stringify(category));
    var data = fetch(DEFAULT_LINK + Config_UpdateCategory, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(category),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisUpdateCategories Service Error: " + error);
    });
    return data;
}

export function ServisDeleteCategories(category: ICategory): Promise<ICategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteCategory, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(category),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteCategories Service Error: " + error);
    });
    return data;
}

export function ServisDeleteProductCategories(productcategory: IProductCategory): Promise<IProductCategory[]> {
    const token = localStorage.getItem('token');
    var data = fetch(DEFAULT_LINK + Config_DeleteProductCategories, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(productcategory),
        redirect: 'follow'
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDeleteProductCategories Service Error: " + error);
    });
    return data;
}


