import BasketStore from "../Stores/BasketStore";
import { IProduct } from "../Models/IProduct";
import { ServisDecrementBasket, ServisDeleteBasket, ServisGetBasket } from "../services/BasketServis";
import { IBasket } from "../Models/IBasket";
import MainStore from "../Stores/MainStore";



export default async function decrementBtn(product: IProduct, props: any) {
    console.log("decrementBtn" + product)

    console.log("decrease" + product)
    const token = await localStorage.getItem("token")
    console.log("Header token check > " + token);
    const new_basket: IBasket = {
        id: 0,
        member: 0,
        token: 1,
        product: product.productId,
        unit: product.product_unit[0].id,
        quantity: 1,
        createdTime: new Date(),
        updatedTime: new Date(),
        isdeleted: false,
        isdeletedReason: new Date(),
        isdeletedTime: new Date()
    }
    if (token == "" || token == null) {
        console.log("none token Basket decrease")
        const basket = BasketStore.getBasket().filter((f) => f.product == product.productId)[0]
        const new_basket = BasketStore.getBasket().filter((f) => f.id != basket.id)
        BasketStore.setBasket(new_basket)
        MainStore.setPageRefresh(!MainStore.getPageRefresh())

    } else {
        console.log("Basket decrease")
        const basket = BasketStore.getBasket().filter((f) => f.product == product.productId)[0]

        ServisDecrementBasket(basket).then((d_baskets) => {
            ServisGetBasket().then((baskets) => {
                BasketStore.setBasket(baskets)
                MainStore.setPageRefresh(!MainStore.getPageRefresh())
            });
        })
        //basket quantity çalışması öncesi 
        // ServisDeleteBasket(basket).then(() => {
        //     const new_basket = BasketStore.getBasket().filter((f) => f.id != basket.id)
        //     BasketStore.setBasket(new_basket)
        // })
    }
}