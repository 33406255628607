// src/Login.js
import React, { useEffect, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import i18n from '../../config/languageConf';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';

const ContactPages = () => {
    const navigate = useNavigate();

    useEffect(() => {
        console.log("NavigationBar useEffect")


    }, [LanguageStore.language, MainStore.PageRefresh]);

    return (
        <><SearchBar /><NavbarButtons />
            <div className="container">
                <hr />
                <div className="row">
                    <div className="col-12 d-flex justify-content-between flex-wrap">
                        <div className="col-12 col-md-3 border rounded mt-3" >
                            <div className="col-12  d-flex justify-content-around mt-3" style={{ color: "#E84B56" }}>
                                <div className="col-1 ms-2"><i className="fa fa-file-text" aria-hidden="true"></i></div>
                                <div className="col-8 ">{i18n.t("iletisimformu")}</div>
                                <div className="col-1 "><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="col-12 mt-3">
                                <h5>{i18n.t("musterihizmetleriiletisim")}</h5>
                            </div>
                            <div className="col-12 d-flex justify-content-between flex-wrap">
                                <div className="col-6 mt-2"><span style={{ fontWeight: "bold" }}>{i18n.t("registerplaceholder2")}:</span>0850 850 50 50</div>
                                <div className="col-6 mt-2"><span style={{ fontWeight: "bold" }} >Mail:</span>miletisim@bisiparis.de</div>
                            </div>
                            <div className="col-12 d-flex justify-content-between flex-wrap mt-3">
                                <div className="col-6 col-md-5 border rounded">
                                    <div className="input-group">
                                        <input style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("Ad")} />
                                    </div>
                                </div>
                                <div className="col-6 col-md-5 border rounded">
                                    <div className="input-group">
                                        <input style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("Soyad")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-between flex-wrap mt-3">
                                <div className="col-6 col-md-5 border rounded">
                                    <div className="input-group">
                                        <input style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("CepTelefonu")} />
                                    </div>
                                </div>
                                <div className="col-6 col-md-5 border rounded">
                                    <div className="input-group">
                                        <input style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("EPosta")} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-between flex-wrap mt-3 border rounded">
                                <div className="input-group">
                                    <input style={{ border: "none" }} type="text" className="form-control" placeholder={i18n.t("konu")} />
                                </div>
                            </div>
                            <div className="col-12 mt-3 border rounded">
                                <textarea className="border-0 w-100" rows={5} style={{ resize: "none", textIndent:"10px" }} placeholder={i18n.t("mesaj")}></textarea>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-start">
                                    <button type="submit" className="col-12 btn btn-outline-dark mt-3 mb-4" >
                                        {i18n.t("degisikliklerikaydet")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};
const ObserverContactPages = observer(ContactPages);
export default ObserverContactPages;
