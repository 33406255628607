import { observable, action, makeObservable, makeAutoObservable } from "mobx";

class MainStore {
  @observable name = 'samet ersoy';
  @observable ListView:boolean = false;
  @observable PageRefresh:boolean = false;
  @observable IsLoading:boolean = false;


  constructor() {
    makeAutoObservable(this);
  }

  @action getIsLoading() {
    return this.IsLoading;
  }

  @action setIsLoading(IsLoading:boolean){
    this.IsLoading = IsLoading;
  }

  @action getPageRefresh() {
    return this.PageRefresh
  }

  @action setPageRefresh(PageRefresh:boolean){
    this.PageRefresh = PageRefresh
  }

  @action getListView() {
    return this.ListView;
  }

  @action setListView(ListView: boolean){
    this.ListView = ListView;
  }


  @action getName() {
    return this.name;
  }

  @action setName(name: string){
    this.name = name;
  }
}

export default new MainStore();