// src/Login.js
import React, { useEffect, useState } from 'react';
import LanguageStore from '../../Stores/LanguageStore';
import MainStore from '../../Stores/MainStore';
import { LoginServis, RegisterServis } from '../../services/Loginservis';
import { IAdminLogin } from '../../Models/ILogin';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import logo from '../../assets/img/logo_navbar.png'
import i18n from '../../config/languageConf';
import { ServisGetProduct } from '../../services/ProductServis';
import ProductStore from '../../Stores/ProductStore';
import { IMember } from '../../Models/IMemberLogin';
import MemberStore from '../../Stores/MemberStore';
import { ServisGetMember } from '../../services/MemberServis';
import { ServisGetBasket } from '../../services/BasketServis';
import BasketStore from '../../Stores/BasketStore';
import { ServisGetFavorites } from '../../services/FavoriteServis';
import FavoriteStore from '../../Stores/FavoriteStore';

const RegisterPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isPasswordMatch, setIsPasswordMatch] = useState(true);

    useEffect(() => {
        console.log("NavigationBar useEffect")


    }, [LanguageStore.language, MainStore.getPageRefresh()]);

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        checkPasswordMatch(event.target.value, confirmPassword);
    };

    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(event.target.value);
        checkPasswordMatch(password, event.target.value);
    };

    const checkPasswordMatch = (password: string, confirmPassword: string) => {
        setIsPasswordMatch(password === confirmPassword);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        console.log('Parola:', password);
        if (!isPasswordMatch) {
            return;
        }

        console.log("Register Scrren Servis Started")
        //@ts-ignore
        const selectedMembers: IMember = {
            id: 0,
            name: MemberStore.getSelectedMembers()?.name || "",
            surname: MemberStore.getSelectedMembers()?.surname || "",
            email: MemberStore.getSelectedMembers()?.email || "",
            phone: MemberStore.getSelectedMembers()?.phone || "",
            password: MemberStore.getSelectedMembers()?.password || "",
            isActive: true,
            createdTime: new Date,
            isGuest: false,
            resetCode: "",
            memberTokens: [],
            orders: [],
            priceType: 0,
            erpCode: ""
        };
        console.log("Register Member > " + JSON.stringify(selectedMembers))
        RegisterServis(selectedMembers ).then(responseregister => {
            console.log(responseregister)
            if (responseregister) {
                LoginServis(selectedMembers.email, selectedMembers.password).then(responseJson => {
                    console.log(responseJson.token)
                    if (responseJson.token) {
                        try {
                            console.log("yazıldı")
                            localStorage.setItem('token', responseJson.token);
                            ServisGetMember().then(member => {
                                console.log("ServisGetMember > " + JSON.stringify(member))
                                MemberStore.setMember(member)
                            })
                            ServisGetBasket().then(basket => {
                                BasketStore.setBasket(basket)
                            });
                            ServisGetFavorites().then(favorites => {
                                console.log("login after favorite > " +  JSON.stringify(favorites))
                                FavoriteStore.setFavorite(favorites)
                            })
                            MainStore.setPageRefresh(!MainStore.getPageRefresh());
        
                            window.location.href = '/';
                        } catch (e) {
                            // saving error
                        }
        
                    } else {
                        console.log("else > " + JSON.stringify(responseJson))
        
                    }
                })
            } else {
                console.log("else > " + JSON.stringify(responseregister))

            }
        }).catch((error) => {

        })
    };

    return (
        <>
            <div className="row">
                <div className='col-12 md-col-6 mt-5 mb-5'>
                    <img width={"100%"} height={"40%"} src={require('../../assets/registerpage.png')} />
                    <div className="row d-flex justify-content-center loginbox mb-5">
                        <div className="col-8 col-md-4 register-border mb-5  ">
                            <div className='col-12 mt-3' style={{ fontWeight: "bold", color: "red", marginBottom: "10px" }}> <h2>{i18n.t("kayitol")}</h2></div>
                            <div className="col-12 mt-2" style={{ color: "gray" }}>{i18n.t("registertext")}</div>
                            <div >
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group ">
                                        <label htmlFor="username"></label>
                                        <input
                                            placeholder={i18n.t("Ad")}
                                            type="text"
                                            className="form-control"
                                            //@ts-ignore
                                            onChange={e => { MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), name: e.target.value }) }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group ">
                                        <label htmlFor="username"></label>
                                        <input
                                            placeholder={i18n.t("Soyad")}
                                            type="text"
                                            className="form-control"
                                            //@ts-ignore
                                            onChange={e => { MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), surname: e.target.value }) }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group ">
                                        <label htmlFor="username"></label>
                                        <input
                                            placeholder={i18n.t("EPosta")}
                                            type="email"
                                            className="form-control"
                                            //@ts-ignore
                                            onChange={e => { MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), email: e.target.value }) }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group ">
                                        <label htmlFor="CepTelefonu"></label>
                                        <input
                                            placeholder={i18n.t("CepTelefonu")}
                                            type="tel"
                                            className="form-control"
                                            minLength={11}
                                            maxLength={11}
                                            //@ts-ignore
                                            onChange={e => { MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), phone: e.target.value }) }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password"></label>
                                        <input
                                            placeholder={i18n.t("YeniSifreniz")}
                                            type="password"
                                            className={`form-control`}
                                            minLength={6}
                                            value={password}
                                            onChange={e => {
                                                    handlePasswordChange(e)
                                                    //@ts-ignore
                                                    MemberStore.setSelectedMembers({ ...MemberStore.getSelectedMembers(), password: e.target.value }) 
                                                }}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword"></label>
                                        <input
                                            placeholder={i18n.t("YeniSifrenizTekrar")}
                                            type="password"
                                            className={`form-control ${!isPasswordMatch && 'is-invalid'}`}
                                            minLength={6}
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            required
                                        />
                                        <div className="invalid-feedback">
                                            {i18n.t("hataliparolatekrari")}
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="col-10 btn btn-danger btn-block mt-3 mb-4">
                                            {i18n.t("kaydol")}
                                        </button>
                                    </div>
                                </form>
                                <div className="col-12  d-flex justify-content-center mb-4">{i18n.t("zatenkayitliysan")} <Link style={{ color: "black", marginLeft: "10px" }} to={'/login'}>{i18n.t("girisyap")}</Link> </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br />
            <br />
            <br />
            <br />
        </>
    );
};
const ObserverLogin = observer(RegisterPage);
export default ObserverLogin;
