import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IMember } from "../Models/IMemberLogin";
import { IMemberSetting } from "../Models/IMemberSetting";
import { IAdminUser } from "../Models/IAdminUser";

class MemberStore {
  @observable Member:IMember = {
    id: 0,
    name: "",
    surname: "",
    email: "",
    phone: "",
    password: "",
    isActive: false,
    createdTime: new Date,
    isGuest: false,
    resetCode: "",
    memberTokens: [],
    orders: [],
    priceType: 0,
    erpCode: ""
  };
  @observable MemberSetting:IMemberSetting[] | null = []
  @observable Members:IMember[] | null = []
  @observable selectedMembers:IMember | null = null
  @observable AdminUser:IAdminUser | null = null




  constructor() {
    makeAutoObservable(this);
  }

  @action getAdminUser() {
    return this.AdminUser
  }

  @action setAdminUser(AdminUser:IAdminUser) {
    this.AdminUser = AdminUser
  }

  @action getMembers() {
    return this.Members;
  }

  @action setMembers(Members: IMember[]){
    this.Members = Members;
  }

  @action getMember() {
    return this.Member;
  }

  @action setMember(Member: IMember){
    this.Member = Member;
  }

  @action getMemberSetting() {
    return this.MemberSetting;
  }

  @action setMemberSetting(MemberSetting: IMemberSetting[]){
    this.MemberSetting = MemberSetting;
  }

  @action getSelectedMembers(){
    return this.selectedMembers;
  }

  @action setSelectedMembers(selectedMembers: IMember){
    this.selectedMembers = selectedMembers;
  }


}

export default new MemberStore();