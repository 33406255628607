import { observable, action, makeObservable, makeAutoObservable } from "mobx";
import { IBasket } from "../Models/IBasket";
import { IProduct } from "../Models/IProduct";

class BasketStore {
  @observable Basket:IBasket[] = [];
  @observable BasketProducts:IProduct[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getBasketProducts(){
    return this.BasketProducts
  }

  setBasketProducts(BasketProducts :IProduct[]){
    this.BasketProducts = BasketProducts;
  }

  getBasket() {
    return this.Basket;
  }

  setBasket(Basket: IBasket[]){
    this.Basket = Basket;
  }
}

export default new BasketStore();