import { useEffect, useState } from "react";
import { ServisGetCity, ServisGetTown } from "../services/GeoServis"

export function GetTownName(cityId: number, townId: number): string {
    const [townName, setTownName] = useState("")
    useEffect(() => {
        async function start() {
            await ServisGetTown(cityId).then(town => {
                //GeoStore.setTown(town)
                setTownName(town.find((f) => f.id == townId)?.townName as "") 
            })
        }
        start()
    }, [cityId]);

    return townName
}

export function GetCityName(cityId: number): string {
    const [cityName, setCityName] = useState("")
    useEffect(() => {
        async function start() {
            await ServisGetCity(213).then(city => {
                setCityName(city.find((f) => f.id == cityId)?.cityName as "") 
            })
        }
        start()
    }, [cityId]);

    return cityName
}