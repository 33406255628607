
import { IBasket } from "../Models/IBasket";
import { Config_AddBasket, Config_DeleteBasket, Config_GetBasket, Config_GetDecrementBasket, DEFAULT_LINK } from "./servisconfig";

export async function ServisGetBasket(): Promise<IBasket[]> {
    const token = localStorage.getItem('token');

    var data = fetch(DEFAULT_LINK + Config_GetBasket, {
        method: "GET",
        headers: {
            'Content-Type': 'text/plain',
            "Authorization": "Bearer " + token
        },
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBasket Service Error: " + error);
    });
    return data;
}

export async function ServisAddBasket(basket:IBasket): Promise<IBasket> {
    const token = localStorage.getItem('token');
    console.log( "ServisAddBasket : "+JSON.stringify(basket));
    var data = fetch(DEFAULT_LINK + Config_AddBasket, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(basket),
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBasket Service Error: " + error);
    });
    return data;
}

export async function ServisDecrementBasket(basket:IBasket): Promise<IBasket> {
    const token = localStorage.getItem('token');
    console.log( "ServisDecrementBasket : "+JSON.stringify(basket));
    var data = fetch(DEFAULT_LINK + Config_GetDecrementBasket, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(basket),
    }).then((response) => response.json()).then((json) => {
        console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisDecrementBasket Service Error: " + error);
    });
    return data;
}

export async function ServisDeleteBasket(basket:IBasket): Promise<IBasket> {
    const token = localStorage.getItem('token');
    console.log(JSON.stringify(basket));
    var data = fetch(DEFAULT_LINK + Config_DeleteBasket, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(basket),
    }).then((response) => response.json()).then((json) => {
        //console.log(JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("ServisGetBasket Service Error: " + error);
    });
    return data;
}