import { IAdminLogin, ILogin, ILoginRequest, ILoginResponse } from "../Models/ILogin";
import { IMember } from "../Models/IMemberLogin";
import { Config_Login, Config_Member_Login, Config_Member_Register_Link, Config_Token_Link, DEFAULT_LINK } from "./servisconfig";



export async function LoginServis(email:string, password:string): Promise<ILogin> {
    //const token = LoginServisToken();
    //console.log("LoginServis servis"+user);
    //console.log(DEFAULT_LINK + Config_Member_Login+"?email="+email+"&password="+password);
    var data = fetch(DEFAULT_LINK + Config_Login + "?email="+email+"&password="+password+"&platform=Web", {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        //body: JSON.stringify(user)
    }).then((response) => response.json()).then((json) => {
        console.log("  LoginServis > " +JSON.stringify(json));
        return json;
    }).catch((error) => {
        console.log("LoginServis Service Error: " + error);
    });
    return data;
}

export async function RegisterServis(member:IMember): Promise<IMember> {
    console.log(DEFAULT_LINK + Config_Member_Register_Link);
    try {
        const response = await fetch(DEFAULT_LINK + Config_Member_Register_Link, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(member)
        })
        if (!response.ok) {
            // Sunucu tarafında hata varsa response.ok false olacaktır.
            throw new Error(`HTTP hata! Status: ${response.status}`);
        }
        const json = await response.json();
        console.log("RegisterServis > " + JSON.stringify(json));
        return json;
    } catch (error) {
        console.log("RegisterServis Service Error: " + error);
        throw error; // Hatanın dışarıya fırlatılması
    }
}
