import React, { useEffect } from 'react';
import SliderProduct from '../../components/Home/SliderProduct';
import CampainBanner from '../../components/Home/CampainBanner';
import NavbarBanner from '../../components/navbarBanner';
import { useLocation, useNavigate } from 'react-router-dom';
import LanguageStore from '../../Stores/LanguageStore';
import { observer } from "mobx-react";
import DiscoverBanner from '../../components/Home/DiscoverBanner';
import SearchBar from '../../components/SearchBar';
import NavbarButtons from '../../components/navbarButtons';
import { ServisGetGlobalSettings } from '../../services/SettingServis';
import GlobalSettingStore from '../../Stores/GlobalSettingStore';
import { ServisGetFavorites } from '../../services/FavoriteServis';
import FavoriteStore from '../../Stores/FavoriteStore';

function Home() {

  useEffect(() => {


    async function start() {
      try {
        const settings = await ServisGetGlobalSettings()
        GlobalSettingStore.setGlobalSetting(settings)

        const favorites = await ServisGetFavorites()
        FavoriteStore.setFavorite(favorites)


      } finally {

      }
    }

    start();

  }, [LanguageStore.language]);
  return (
    <div>
      <SearchBar />
      <NavbarButtons />
      <NavbarBanner />
      <SliderProduct />
      <CampainBanner />
      <DiscoverBanner />

    </div>
  );
}

const ObserverSCampainBanner = observer(Home);
export default ObserverSCampainBanner;

